import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DataTable, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import classNames from 'classnames';
import moment from 'moment';
import Footer from '../../Common/Footer/Footer';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import {selectLoading} from '../../../store/slices/selectors';
import ProductsDialog from '../ProductsDialog/ProductsDialog';
import {urls} from '../../../apiRequestService';
import {slicesNames} from '../../../store/slices/constants';
import {customersThunkNames} from '../../../store/slices/customers/constants';
import {ICustomersParams, ICustomersProps} from '../types';
import {IPagination, ITableSort} from '../../../globalTypes';
import {ICompany, IProduct} from '../../../api/customers/types';
import {dateFormat, dateWithFullTimeFormat} from '../../../globalConstants';
import {initialCustomersParams} from '../constants';
import styles from './CustomersList.module.scss';

const CustomersList: FC<ICustomersProps> = ({customers, setCustomersParams, customersParams, totalRecords}) => {
    const [sort, setSort] = useState<ITableSort>({field: 'name', order: 1});
    const [pagination, setPagination] = useState({first: 0, rows: 20});
    const [productsDialog, setProductsDialog] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const tableRef = useRef(null);

    const isLoading = useSelector(selectLoading(slicesNames.customers, customersThunkNames.fetchCustomers));
    const navigate = useNavigate();

    const breadCrumbItems = useMemo(() => [{label: 'Customers'}], []);

    const onSort = useCallback(
        ({sortField, sortOrder}: {sortField: string; sortOrder: DataTableSortOrderType}) => {
            setSort({field: sortField, order: sortOrder});
            setCustomersParams((oldParams: ICustomersParams) => {
                const {query} = oldParams;
                return {
                    query: query,
                    navigation: {
                        page: {from: 0, size: 20},
                        sort: [{key: sortField, order: sortOrder === 1 ? 'ASC' : 'DESC'}],
                    },
                };
            });
        },
        [setCustomersParams],
    );

    const nameBodyTemplate = useCallback((rowData: ICompany) => {
        return <div className={styles.linkStyle}>{rowData.name}</div>;
    }, []);

    const signInBodyTemplate = useCallback((rowData: ICompany) => {
        return (
            <>
                {rowData.signIn ? (
                    <i className={classNames('pi pi-circle-fill', styles.signedIn)} />
                ) : (
                    <i className={classNames('pi pi-circle', styles.signedIn)} />
                )}
            </>
        );
    }, []);

    const lastSignInBodyTemplate = useCallback((rowData: ICompany) => {
        const formattedDate = rowData.lastSignIn ? moment(rowData.lastSignIn).format(dateWithFullTimeFormat.US) : '';
        return <>{formattedDate}</>;
    }, []);

    const startDateBodyTemplate = useCallback((rowData: ICompany) => {
        const formattedDate = rowData.createdDate ? moment(rowData.createdDate).format(dateFormat.US) : '';
        return <>{formattedDate}</>;
    }, []);

    const toggleShowDialog = useCallback((rowData: ICompany) => {
        const products = rowData.products.map((product: IProduct) => product.fullName);
        setProductsDialog(products);
        setSelectedCustomer(rowData.name);
    }, []);

    const hideHandler = useCallback(() => {
        setProductsDialog([]);
        setSelectedCustomer('');
    }, []);

    const productsBodyTemplate = useCallback(
        (rowData: ICompany) => {
            return (
                <Button
                    icon="pi pi-list"
                    className={classNames('p-button-text', styles.productCell)}
                    aria-label="Submit"
                    onClick={() => toggleShowDialog(rowData)}
                />
            );
        },
        [toggleShowDialog],
    );

    const onPageSelect = useCallback(
        (pagination: IPagination) => {
            setPagination({first: pagination.first, rows: pagination.rows});
            setCustomersParams(() => {
                return {
                    query: {},
                    navigation: {
                        sort: [
                            {
                                key: 'name',
                                order: 'ASC',
                            },
                        ],
                        page: {from: pagination.first, size: pagination.rows},
                    },
                };
            });
        },
        [setCustomersParams],
    );

    const onCustomerSelect = useCallback(
        (event: any) => {
            const customerId = event.rowData.id;

            navigate(`/${urls.CUSTOMERS}/${customerId}`, {
                state: {
                    name: event.rowData.name,
                },
            });
        },
        [navigate],
    );

    return (
        <div className={classNames(styles.customersList, 'list-generic')}>
            <BreadCrumb items={breadCrumbItems} />
            <DataTable
                ref={tableRef}
                className="table-generic p-datatable-sm"
                value={customers}
                reorderableColumns
                resizableColumns
                cellSelection
                onCellSelect={onCustomerSelect}
                columnResizeMode="expand"
                selectionMode="single"
                dataKey="id"
                sortField={sort.field}
                sortOrder={sort.order}
                onSort={onSort}
                scrollable={true}
                emptyMessage=""
                loading={isLoading}
                frozenWidth="220px">
                <Column
                    className="p-text-nowrap p-text-truncate"
                    field="name"
                    columnKey="name"
                    header="Name"
                    body={nameBodyTemplate}
                    headerStyle={{width: '270px', height: '48px'}}
                    bodyStyle={{height: '50px'}}
                    sortable
                    sortFunction={() => customers}
                    frozen
                />
                <Column
                    field="createdDate"
                    columnKey="createdDate"
                    header="Start Date"
                    headerStyle={{height: '48px', width: '150px'}}
                    body={startDateBodyTemplate}
                    bodyStyle={{height: '50px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="signedIn"
                    columnKey="signedIn"
                    header="Signed In"
                    body={signInBodyTemplate}
                    headerStyle={{height: '48px', width: '100px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="lastSignIn"
                    columnKey="lastSignIn"
                    header="Last Login"
                    body={lastSignInBodyTemplate}
                    headerStyle={{height: '48px', width: '200px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="address.stateId"
                    columnKey="address.stateId"
                    header="State/Province"
                    headerStyle={{height: '48px', width: '150px'}}
                />
                <Column
                    field="address.countryId"
                    columnKey="address.countryId"
                    header="Country"
                    headerStyle={{height: '48px', width: '150px'}}
                />
                <Column
                    field="phoneNumber"
                    columnKey="phoneNumber"
                    header="Phone Number"
                    headerStyle={{height: '48px', width: '150px'}}
                />
                <Column
                    field="products"
                    columnKey="products"
                    header="Products"
                    body={productsBodyTemplate}
                    headerStyle={{height: '48px', width: '100px'}}
                />
            </DataTable>
            <Footer
                totalRecords={totalRecords}
                initParams={initialCustomersParams}
                params={customersParams}
                pagination={pagination}
                type="customer"
                onPageSelect={onPageSelect}
            />
            <ProductsDialog
                visible={!!productsDialog.length}
                header={selectedCustomer}
                onHideHandler={hideHandler}
                products={productsDialog}
            />
        </div>
    );
};

export default CustomersList;
