import jwtDecode from 'jwt-decode';
import {removeAuthCookie, updateAuthCookieExpiration, isAuthCookie} from './auth-cookie';
import {GetTokenSilentlyOptions} from '@auth0/auth0-react';

const getAuthConfig = async (
    checkTokenExp = true,
    logout: () => void,
    getAccessTokenSilently: {
        (options: GetTokenSilentlyOptions & {detailedResponse: true}): Promise<GetTokenSilentlyOptions>;
        (options?: GetTokenSilentlyOptions): Promise<string>;
        (options: GetTokenSilentlyOptions): Promise<GetTokenSilentlyOptions | string>;
        (options: GetTokenSilentlyOptions): () => string;
    },
) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    let token = localStorage.getItem('token');
    if (checkTokenExp) {
        token = await getAccessToken(logout, getAccessTokenSilently);
    }
    return {headers: {Authorization: `Bearer ${token}`}, apiUrl: apiUrl};
};

const getAccessToken = async (
    logout: () => void,
    getAccessTokenSilently: {
        (options: GetTokenSilentlyOptions & {detailedResponse: true}): Promise<GetTokenSilentlyOptions>;
        (options?: GetTokenSilentlyOptions): Promise<string>;
        (options: GetTokenSilentlyOptions): Promise<GetTokenSilentlyOptions | string>;
        (options: GetTokenSilentlyOptions): () => string;
    },
) => {
    let token = localStorage.getItem('token') || '';
    if (isAuthCookie() && (!token || isTokenExpired(token))) {
        updateAuthCookieExpiration();
        if (!token || isTokenExpired(token)) {
            token = await getAccessTokenSilently();
            localStorage.setItem('token', token);
        }
    }

    if (!isAuthCookie() && token && !isTokenExpired(token)) {
        updateAuthCookieExpiration();
    }
    if (!isAuthCookie() && token && isTokenExpired(token)) {
        customLogout(logout);
    }

    return token;
};

const isTokenExpired = (token: string) => {
    const {exp}: {exp: number} = jwtDecode(token);
    const expirationTime = exp * 1000 - 60000;
    return Date.now() >= expirationTime;
};

const customLogout = (logout: (o: object) => void) => {
    removeAuthCookie();
    localStorage.clear();
    logout({returnTo: window.location.origin});
};

const isAuth = () => {
    const token = localStorage.getItem('token');
    return isAuthCookie() || (token && !isTokenExpired(token));
};

export {getAuthConfig, isAuth, customLogout};
