import {fetchProfileInfo, savePermissions} from './thunks';
import createGenericSlice from '../createGenericSlice';
import {slicesNames} from '../constants';

export const profileInfoSlice = createGenericSlice({
    name: slicesNames.profileInfo,
    initialState: {
        data: {
            role: '',
            profile: {
                products: [],
                user: {},
            },
        },
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProfileInfo.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data.profile = payload;
            }
        });
        builder.addCase(savePermissions.fulfilled, (state, {payload}) => {
            if (payload) state.data.role = payload;
        });
    },
});

export const {resetSlice} = profileInfoSlice.actions;
