import * as yup from 'yup';
import {IInitialInfo} from '../types';

export const initialManageTeamFormSchema = (userInfo: IInitialInfo) => {
    return {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        roles: userInfo.roles[0]?.name && userInfo.roles.map((role) => ({name: role.name, type: role.type})),
    };
};

const lengthError = 'Maximum length exceeded.';

export const manageTeamFormSchema = yup.object().shape({
    firstName: yup
        .string()
        .required('User First Name should be provided.')
        .matches(
            /^[a-z](?:_?[a-z\s]+)*$/i,
            'First Name should start with a letter and can only contain letters and spaces.',
        )
        .max(100, lengthError),
    lastName: yup
        .string()
        .required('User Last Name should be provided.')
        .matches(
            /^[a-z](?:_?[a-z\s]+)*$/i,
            'Last Name should start with a letter and can only contain letters and spaces.',
        )
        .max(100, lengthError),
    email: yup.string().required('User Email should be provided.').email('Invalid email format.').max(100, lengthError),
});
