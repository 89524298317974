import React, {FC, useMemo} from 'react';
import {Dialog} from 'primereact/dialog';
import styles from '../../SuperUsers/SuperUsersList/SuperUsersList.module.scss';
import {Button} from 'primereact/button';

interface IDialog {
    visible: boolean;
    header: string;
    footerConfirmHandler: () => void;
    onHideHandler: () => void;
    message: string;
    name: string;
}

const CustomDialog: FC<IDialog> = ({visible, footerConfirmHandler, onHideHandler, name, message, header}) => {
    const footerTemplate = useMemo(
        () => (
            <>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={onHideHandler} />
                <Button
                    label="Yes"
                    icon="pi pi-check"
                    className="p-button-raised p-button-danger"
                    onClick={footerConfirmHandler}
                />
            </>
        ),
        [footerConfirmHandler, onHideHandler],
    );

    return (
        <Dialog
            visible={visible}
            className={styles.dialog}
            header={header}
            footer={footerTemplate}
            onHide={onHideHandler}>
            <div className="confirmation-content">
                <i className="pi pi-info-circle p-mr-3" />
                {message} &nbsp; <b>{name}</b>?
            </div>
        </Dialog>
    );
};

export default CustomDialog;
