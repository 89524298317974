import {createAsyncThunk} from '@reduxjs/toolkit';
import {IActionData} from '../../../apiRequestService/types';
import {slicesNames} from '../constants';
import {analyticsKpiThunkNames} from './constants';
import {getAnalyticsKpi} from '../../../api/analyticsKPI';
import {setPopUp} from '../popUp/popUpSlice';
import {defaultErrorMessage, popUpInfo} from '../../../globalConstants';

export const fetchAnalyticsKpi = createAsyncThunk(
    `${slicesNames.analyticsKpi}/${analyticsKpiThunkNames.fetchAnalyticsKpi}`,
    async (data: IActionData, {dispatch}) => {
        try {
            const response = await getAnalyticsKpi(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);
