import {ISelectedCustomer} from '../../../api/customers/types';
import {getCountryNameByID, getStateNameByID} from '../../Common/utils';

export const initialCustomerInfoSchema = (customerInfo: ISelectedCustomer) => {
    return {
        id: customerInfo.id || '',
        name: customerInfo.name || '',
        webAddress: customerInfo.webAddress || '',
        phoneNumber: customerInfo.phoneNumber || '',
        address: customerInfo.address.address || '',
        address2: customerInfo.address.address2 || '',
        city: customerInfo.address.city || '',
        countryId: getCountryNameByID(customerInfo.address.countryId) || '',
        stateId: getStateNameByID(customerInfo.address.countryId, customerInfo.address.stateId) || '',
        postalCode: customerInfo.address.postalCode || '',
    };
};
