import {sendRequest, urls} from '../../apiRequestService';
import {requestMethod, responseType} from '../../globalConstants';
import {ICreateUserData, IDeleteUserData, IGetProductsData, IGetRolesData, IGetUserData, IGetUsersData} from './types';

export const getUsers = (data: IGetUsersData) => {
    return sendRequest(urls.USERS_SEARCH, requestMethod.POST, responseType.JSON, data);
};

export const getUser = (data: IGetUserData) => {
    return sendRequest(`${urls.USERS}/${data.id}`, requestMethod.GET, responseType.JSON, data);
};

export const deleteUser = (data: IDeleteUserData) => {
    return sendRequest(urls.USERS, requestMethod.DELETE, responseType.JSON, data);
};

export const createUser = (data: ICreateUserData) => {
    return sendRequest(urls.USERS, requestMethod.POST, responseType.JSON, data);
};

export const updateUser = (data: ICreateUserData) => {
    return sendRequest(`${urls.USERS}/${data.id}`, requestMethod.POST, responseType.JSON, data);
};

export const getRoles = (data: IGetRolesData) => {
    return sendRequest(urls.ROLES, requestMethod.GET, responseType.JSON, data);
};

export const getProducts = (data: IGetProductsData) => {
    return sendRequest(urls.PRODUCTS, requestMethod.POST, responseType.JSON, data);
};
