import React, {useCallback, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Toast} from 'primereact/toast';
import {useAppDispatch} from '../../../hooks/redux';
import {setPopUp} from '../../../store/slices/popUp/popUpSlice';
import {selectPopUp} from '../../../store/slices/popUp/selectors';

interface ISelectedPopUp {
    severity: string;
    summary: string;
    detail: string;
    life: number;
    sticky: string;
}

const Toaster = () => {
    const toast = useRef(null);
    const dispatch = useAppDispatch();
    const popUp = useSelector(selectPopUp);

    const showPopUp = useCallback(
        ({severity, summary, detail, life, sticky}: ISelectedPopUp) => {
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: detail,
                life: life,
                sticky: sticky,
            });

            return dispatch(
                setPopUp({
                    popUp: {
                        severity: '',
                        summary: '',
                        detail: '',
                        life: 0,
                        sticky: '',
                    },
                }),
            );
        },
        [dispatch],
    );

    useEffect(() => {
        popUp.detail && showPopUp(popUp);
    }, [popUp, showPopUp]);

    return <Toast ref={toast} />;
};

export default Toaster;
