import React, {FC, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useAuth0} from '@auth0/auth0-react';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import CustomDialog from '../../../Common/CustomDialog/CustomDialog';
import {useAppDispatch} from '../../../../hooks/redux';
import {changeSubscription, fetchCustomerSubscriptions} from '../../../../store/slices/customers/thunks';
import {selectBillingDetails, selectSubscriptions} from '../../../../store/slices/customers/selectors';
import {ISubscription} from '../../../../api/customers/types';
import styles from './SubscriptionList.module.scss';

interface IDialog {
    visible: boolean;
    header: string;
    onHideHandler: () => void;
    setShowList: (arg: boolean) => void;
    fetchBillingDetails: () => void;
}

const SubscriptionList: FC<IDialog> = ({visible, onHideHandler, header, setShowList, fetchBillingDetails}) => {
    const [subscriptionToChange, setSubscriptionToChange] = useState(null);

    const subscriptions = useSelector(selectSubscriptions);
    const billingDetails = useSelector(selectBillingDetails);
    const {id} = useParams();

    const dispatch = useAppDispatch();
    const {logout, getAccessTokenSilently} = useAuth0();

    const handleSubscription = (e: any, id: string) => {
        setSubscriptionToChange({name: e.target.value, companyId: id, id: e.target.id});
    };

    const hideDialog = useCallback(() => {
        setSubscriptionToChange(null);
    }, []);

    const updateSubscription = useCallback(
        (subscription: {name: string; companyId: string; id: string}) => {
            const actionData = {
                id: subscription.companyId,
                params: {newPriceId: subscription.id},
                logout: logout,
                getAccessTokenSilently: getAccessTokenSilently,
            };

            dispatch(changeSubscription(actionData))
                .then(() => {
                    hideDialog();
                })
                .then(() => {
                    fetchBillingDetails();
                })
                .then(() => {
                    setShowList(false);
                });
        },
        [dispatch, fetchBillingDetails, getAccessTokenSilently, hideDialog, logout, setShowList],
    );

    const fetchSubscriptions = useCallback(() => {
        const actionData = {
            params: {},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };

        dispatch(fetchCustomerSubscriptions(actionData));
    }, [dispatch, getAccessTokenSilently, logout]);

    useEffect(() => {
        fetchSubscriptions();
    }, [fetchSubscriptions]);

    return (
        <Dialog visible={visible} className={styles.subscriptionList} header={header} onHide={onHideHandler}>
            <div className={styles.subscriptionList}>
                {subscriptions.map((subscription: ISubscription, index) => {
                    return (
                        subscription.name !== billingDetails.subscription.name && (
                            <Button
                                key={index}
                                className="p-button-text"
                                id={subscription.prices[0].id}
                                value={subscription.name}
                                onClick={(e) => handleSubscription(e, id)}>
                                {subscription.name}
                            </Button>
                        )
                    );
                })}
            </div>
            <CustomDialog
                visible={subscriptionToChange?.id}
                header="Confirmation of change"
                footerConfirmHandler={() => updateSubscription(subscriptionToChange)}
                onHideHandler={hideDialog}
                message="Are you sure you want to change subscription plan to"
                name={subscriptionToChange?.name}
            />
        </Dialog>
    );
};

export default SubscriptionList;
