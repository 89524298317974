import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {FormikProps, useFormik} from 'formik';
import {Divider} from 'primereact/divider';
import LeftBar from '../../LeftBar/LeftBar';
import CustomInput from '../../Common/CustomInput/CustomInput';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import CustomerMenu from '../CustomerMenu/CustomerMenu';
import {selectCustomer} from '../../../store/slices/customers/selectors';
import {initialCustomerInfoSchema} from './CustomerInfoValidation';
import {IMappedCustomer} from '../../../api/customers/types';
import {inputTypes, LINKS} from '../../../globalConstants';
import styles from './CustomerInfo.module.scss';

const CustomerInfo = () => {
    const selectedCustomer = useSelector(selectCustomer);
    const navigate = useNavigate();
    const breadCrumbItems = useMemo(
        () => [
            {
                label: 'Customers',
                command: () => {
                    navigate(LINKS.CUSTOMERS);
                },
            },
            {label: selectedCustomer.name},
        ],
        [navigate, selectedCustomer.name],
    );

    const formik: FormikProps<IMappedCustomer> = useFormik<IMappedCustomer>({
        initialValues: initialCustomerInfoSchema(selectedCustomer),
        enableReinitialize: true,
        onSubmit: null,
    });

    return (
        <>
            <LeftBar>
                <CustomerMenu />
            </LeftBar>
            <div className={styles.customerInfo}>
                <BreadCrumb items={breadCrumbItems} />
                <div className={styles.customerForm}>
                    <h3>Customer Information</h3>
                    <Divider />
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid">
                            <CustomInput
                                id="name"
                                type={inputTypes.text}
                                formik={formik}
                                inputCols="p-col-12 md:col-5"
                                labelText="Name"
                                readOnly={true}
                            />
                            <CustomInput
                                id="address"
                                type={inputTypes.text}
                                formik={formik}
                                inputCols="p-col-12 md:col-5"
                                labelText="Address"
                                readOnly={true}
                            />
                            <CustomInput
                                id="address2"
                                type={inputTypes.text}
                                formik={formik}
                                inputCols="p-col-12 md:col-5"
                                labelText="Address Line 2"
                                readOnly={true}
                            />
                            <CustomInput
                                id="city"
                                type={inputTypes.text}
                                formik={formik}
                                inputCols="p-col-12 md:col-5"
                                labelText="City"
                                readOnly={true}
                            />
                            <CustomInput
                                id="stateId"
                                type={inputTypes.text}
                                formik={formik}
                                inputCols="p-col-12 md:col-5"
                                labelText="State/Province"
                                readOnly={true}
                            />
                            <CustomInput
                                id="countryId"
                                type={inputTypes.text}
                                formik={formik}
                                inputCols="p-col-12 md:col-5"
                                labelText="Country"
                                readOnly={true}
                            />
                            <CustomInput
                                id="postalCode"
                                type={inputTypes.text}
                                formik={formik}
                                inputCols="p-col-12 md:col-5"
                                labelText="Postal Code"
                                readOnly={true}
                            />
                            <CustomInput
                                id="phoneNumber"
                                type={inputTypes.text}
                                formik={formik}
                                inputCols="p-col-12 md:col-5"
                                labelText="Phone Number"
                                readOnly={true}
                            />
                            <CustomInput
                                id="webAddress"
                                type={inputTypes.text}
                                formik={formik}
                                inputCols="p-col-12 md:col-5"
                                labelText="Web Address"
                                readOnly={true}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CustomerInfo;
