import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAuth0} from '@auth0/auth0-react';
import moment from 'moment';
import {isEmpty} from 'lodash';
import ProductsList from '../ProductsList/ProductsList';
import {useAppDispatch} from '../../../hooks/redux';
import {selectTotalRecords, selectProducts} from '../../../store/slices/products/selectors';
import {fetchProducts} from '../../../store/slices/products/thunks';
import {IProduct} from '../../../api/products/types';
import {initialProductParams} from '../constants';
import {dateFormat} from '../../../globalConstants';
import styles from './ProductsPage.module.scss';

const ProductsPage: FC = () => {
    const [products, setProducts] = useState([]);
    const [productsParams, setProductsParams] = useState(initialProductParams);

    const dispatch = useAppDispatch();
    const {logout, getAccessTokenSilently} = useAuth0();
    const selectedProducts = useSelector(selectProducts);
    const totalRecords = useSelector(selectTotalRecords);

    const actionData = useMemo(
        () => ({
            params: productsParams,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [getAccessTokenSilently, logout, productsParams],
    );

    const prepareProducts = useCallback((selectedProducts: IProduct[]) => {
        const products = selectedProducts.map((product: IProduct) => {
            return {
                name: product.name,
                versionNumber: product.versionNumber,
                signInUserCount: product.signInUserCount ? product.signInUserCount : 0,
                updateDatetime: moment(product.updateDatetime).format(dateFormat.US),
            };
        });
        setProducts(products);
    }, []);

    useEffect(() => {
        dispatch(fetchProducts(actionData));
    }, [productsParams, actionData, dispatch]);

    useEffect(() => {
        !isEmpty(selectedProducts) && prepareProducts(selectedProducts);
    }, [prepareProducts, selectedProducts]);

    return (
        <div className={styles.productsPage}>
            <ProductsList
                products={products}
                productsParams={productsParams}
                totalRecords={totalRecords}
                setProductsParams={setProductsParams}
            />
        </div>
    );
};

export default ProductsPage;
