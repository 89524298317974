export const initialCustomersParams = {
    query: {},
    navigation: {
        sort: [
            {
                key: 'name',
                order: 'ASC',
            },
        ],
        page: {
            from: 0,
            size: 20,
        },
    },
};

export const getInitialBillingHistoryParams = (id: number) => ({
    query: {
        companyId: id,
    },
    navigation: {
        sort: [
            {
                key: 'name',
                order: 'ASC',
            },
        ],
        page: {
            from: 0,
            size: 20,
        },
    },
});

export const paymentLogoStyle = {
    VISA: 'visa',
    MASTERCARD: 'mastercard',
    UNKNOWN: '',
};

export const upperCaseToCapitalize = (string: string) => {
    return string ? string.charAt(0) + string.slice(1).toLowerCase() : '';
};
