import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import classNames from 'classnames';
import moment from 'moment';
import {Button} from 'primereact/button';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import SubscriptionList from '../SubscriptionList/SubscriptionList';
import {selectBillingDetails, selectCustomer} from '../../../../store/slices/customers/selectors';
import {fetchCustomerBillingDetails} from '../../../../store/slices/customers/thunks';
import {useAppDispatch} from '../../../../hooks/redux';
import {dateFormat, LINKS} from '../../../../globalConstants';
import styles from './SubscriptionsInfo.module.scss';

const SubscriptionsInfo = () => {
    const [showList, setShowList] = useState(false);

    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {logout, getAccessTokenSilently} = useAuth0();

    const billingDetails = useSelector(selectBillingDetails);
    const selectedCustomer = useSelector(selectCustomer);

    const breadCrumbItems = useMemo(
        () => [
            {
                label: 'Customers',
                command: () => {
                    navigate(LINKS.CUSTOMERS);
                },
            },
            {
                label: selectedCustomer.name,
                command: () => {
                    navigate(`${LINKS.CUSTOMERS}/${id}`);
                },
            },
            {label: 'Subscriptions'},
        ],
        [id, navigate, selectedCustomer],
    );

    const fetchBillingDetails = useCallback(() => {
        const actionData = {
            params: {},
            id: +id,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };

        dispatch(fetchCustomerBillingDetails(actionData));
    }, [id, dispatch, getAccessTokenSilently, logout]);

    const handleShow = () => {
        setShowList((prevState) => !prevState);
    };

    useEffect(() => {
        id && fetchBillingDetails();
    }, [fetchBillingDetails, id]);

    return (
        <div className={classNames('pageWrapper', styles.subscriptionInfo)}>
            <BreadCrumb items={breadCrumbItems} />
            <SubscriptionList
                visible={showList}
                header="Choose Subscription Plan"
                onHideHandler={handleShow}
                setShowList={setShowList}
                fetchBillingDetails={fetchBillingDetails}
            />
            <div className={styles.infoWrapper}>
                <div className={styles.header}>Active Subscriptions</div>

                <div className={styles.card}>
                    <div className={styles.left}>
                        <div className={classNames(styles.imageWrapper)} />
                        <div className={styles.info}>
                            <div className={styles.simpleText}>
                                {moment(billingDetails.subscription.expiredAt).format(dateFormat.US)}
                            </div>
                            <div className={styles.simpleText}>
                                Subscription Plan: {billingDetails.subscription.name}
                            </div>
                            <div className={styles.simpleText}>Users: {billingDetails.userCount}</div>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <Button label="Change Plan" onClick={handleShow} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionsInfo;
