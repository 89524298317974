import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Dialog} from 'primereact/dialog';
import {Divider} from 'primereact/divider';
import {Button} from 'primereact/button';
import {MultiSelect} from 'primereact/multiselect';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {FormikProps, useFormik} from 'formik';
import classNames from 'classnames';
import CustomInput from '../../Common/CustomInput/CustomInput';
import {initialManageTeamFormSchema, manageTeamFormSchema} from './manageteamFormValidation';
import {useAppDispatch} from '../../../hooks/redux';
import {addUser, editUser, fetchRoles, fetchUser, fetchUsers} from '../../../store/slices/manageTeam/thunks';
import {selectRoles, selectUser} from '../../../store/slices/manageTeam/selectors';
import {clearSelectedUser} from '../../../store/slices/manageTeam/manageTeamSlice';
import {IInitialInfo, IManageTeamsFormProps} from '../types';
import {ICreateUserData, IRole} from '../../../api/manageTeam/types';
import {inputTypes} from '../../../globalConstants';
import styles from './ManageTeamForm.module.scss';

const ManageTeamForm: FC<IManageTeamsFormProps> = ({
    displayUserModal,
    setDisplayUserModal,
    setSelectedUser,
    userId,
    logout,
    getAccessTokenSilently,
    usersActionData,
}) => {
    const dispatch = useAppDispatch();

    const [roleOptions, setRoleOptions] = useState([]);

    const roles = useSelector(selectRoles);
    const user = useSelector(selectUser);

    const actionData = useMemo(
        () => ({
            id: userId,
            params: {},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [getAccessTokenSilently, logout, userId],
    );

    const rolesActionData = useMemo(
        () => ({
            params: {},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [getAccessTokenSilently, logout],
    );

    const handleSubmit = (values: IInitialInfo): void => {
        if (userId) {
            const newUser = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                roles: values.roles ? values.roles?.map((role: IRole) => role.type) : [],
            };

            const actionData = {
                logout: logout,
                getAccessTokenSilently: getAccessTokenSilently,
                params: newUser,
                id: userId,
            };

            dispatch(editUser(actionData as ICreateUserData))
                .then(() => {
                    accept();
                })
                .then(() => {
                    dispatch(fetchUsers(usersActionData));
                });
        } else {
            const newUser = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                roles: values.roles ? values.roles?.map((role: IRole) => role.type) : [],
            };

            const actionData = {
                logout: logout,
                getAccessTokenSilently: getAccessTokenSilently,
                params: newUser,
            };

            dispatch(addUser(actionData as ICreateUserData))
                .then(() => {
                    accept();
                })
                .then(() => {
                    dispatch(fetchUsers(usersActionData));
                });
        }
    };

    const formik: FormikProps<IInitialInfo> = useFormik<IInitialInfo>({
        initialValues: initialManageTeamFormSchema(user),
        enableReinitialize: true,
        validationSchema: manageTeamFormSchema,
        onSubmit: handleSubmit,
    });

    const onHide = useCallback(() => {
        setDisplayUserModal(false);
        setSelectedUser(null);
        formik.resetForm();
        dispatch(clearSelectedUser());
    }, [dispatch, formik, setDisplayUserModal, setSelectedUser]);

    const accept = useCallback(() => {
        onHide();
    }, [onHide]);

    const confirm = useCallback(
        (dirty: boolean) => {
            if (dirty) {
                confirmDialog({
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    message: 'Are you sure you want to discard the changes?',
                    accept,
                });
            } else {
                accept();
            }
        },
        [accept],
    );

    useEffect(() => {
        dispatch(fetchRoles(rolesActionData));
    }, [actionData, dispatch, rolesActionData]);

    useEffect(() => {
        if (roles) {
            setRoleOptions(
                roles.map((role: IRole) => {
                    return {name: role.name, type: role.type};
                }),
            );
        }
    }, [roles]);

    useEffect(() => {
        userId && dispatch(fetchUser(actionData));
    }, [actionData, dispatch, userId]);

    return (
        <div>
            <ConfirmDialog />
            <Dialog
                className={styles.manageTeamFormDialog}
                header="Employee Information"
                visible={displayUserModal}
                onHide={() => confirm(formik.dirty)}>
                <Divider />
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid">
                        <CustomInput
                            id="firstName"
                            type={inputTypes.text}
                            formik={formik}
                            labelText="First Name*"
                            labelBoldText={true}
                            warningText="Employee First Name is required."
                        />
                        <CustomInput
                            id="lastName"
                            type={inputTypes.text}
                            formik={formik}
                            labelText="Last Name*"
                            labelBoldText={true}
                            warningText="Employee Last Name is required."
                        />
                        <CustomInput
                            id="email"
                            type={inputTypes.text}
                            formik={formik}
                            labelText="Email*"
                            labelBoldText={true}
                            warningText="Employee Email is required."
                        />
                        <div className="p-field grid p-ai-start">
                            <label htmlFor="roles" className="p-col-12 md:col-3">
                                Roles
                            </label>
                            <div className="p-col-12 md:col-9">
                                <MultiSelect
                                    id="roles"
                                    value={formik.values.roles}
                                    aria-describedby="roles-invalid"
                                    options={roleOptions}
                                    onChange={formik.handleChange}
                                    optionLabel="name"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classNames('grid p-col-12 pad-r-0 margin-l-0', styles.buttonGroup)}>
                        <Button
                            className={styles.button}
                            label="Submit"
                            type="submit"
                            disabled={!formik.dirty || !formik.isValid}
                            icon="pi pi-check"
                            autoFocus
                        />
                        <Button
                            className={`p-button-secondary ${styles.button}`}
                            label="Cancel"
                            type="button"
                            icon="pi pi-times"
                            onClick={() => confirm(formik.dirty)}
                        />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};

export default ManageTeamForm;
