import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {customersThunkNames} from './constants';
import {
    changeCustomerSubscription,
    getCustomer,
    getCustomerBillingDetails,
    getCustomerBillingHistory,
    getCustomers,
    getCustomerSubscriptions,
} from '../../../api/customers';
import {setPopUp} from '../popUp/popUpSlice';
import {defaultErrorMessage, popUpInfo} from '../../../globalConstants';
import {ICustomersData} from '../../../api/customers/types';

export const fetchCustomers = createAsyncThunk(
    `${slicesNames.customers}/${customersThunkNames.fetchCustomers}`,
    async (data: ICustomersData, {dispatch}) => {
        try {
            const response = await getCustomers(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const fetchCustomer = createAsyncThunk(
    `${slicesNames.customers}/${customersThunkNames.fetchCustomer}`,
    async (data: ICustomersData, {dispatch}) => {
        try {
            const response = await getCustomer(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const changeSubscription = createAsyncThunk(
    `${slicesNames.customers}/${customersThunkNames.changeCustomerSubscription}`,
    async (data: ICustomersData, {dispatch}) => {
        try {
            await changeCustomerSubscription(data);
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.SUCCESS,
                        summary: popUpInfo.summary.SUCCESSFUL,
                        detail: 'Subscription plan was changed successfully!',
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const fetchCustomerBillingDetails = createAsyncThunk(
    `${slicesNames.customers}/${customersThunkNames.fetchCustomerBillingDetails}`,
    async (data: ICustomersData, {dispatch}) => {
        try {
            const response = await getCustomerBillingDetails(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const fetchCustomerBillingHistory = createAsyncThunk(
    `${slicesNames.customers}/${customersThunkNames.fetchCustomerBillingHistory}`,
    async (data: ICustomersData, {dispatch}) => {
        try {
            const response = await getCustomerBillingHistory(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);
export const fetchCustomerSubscriptions = createAsyncThunk(
    `${slicesNames.customers}/${customersThunkNames.fetchCustomerSubscriptions}`,
    async (data: ICustomersData, {dispatch}) => {
        try {
            const response = await getCustomerSubscriptions(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);
