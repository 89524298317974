import React, {FC, ReactNode, useState} from 'react';
import './LeftBar.scss';

type Props = {children?: ReactNode};

const LeftBar: FC<Props> = ({children}) => {
    const [visible, setVisible] = useState(true);

    const toggleVisible = () => {
        setVisible((isVisible) => !isVisible);
    };

    return (
        <div className={`leftbar-container ${visible ? '' : 'leftbar-hidden'}`}>
            <div className="leftbar-content">{children}</div>
            <button type="button" className="p-link bar-toogle-btn" onClick={toggleVisible}>
                <i className="pi pi-chevron-left" />
            </button>
        </div>
    );
};
export default LeftBar;
