import {combineReducers, configureStore} from '@reduxjs/toolkit';
import popUp from './slices/popUp/popUpSlice';
import analyticsCustomers from './slices/analyticsCustomers';
import analyticsKpi from './slices/analyticsKPI';
import products from './slices/products';
import profileInfo from './slices/profileInfo';
import superUsers from './slices/superUsers';
import customers from './slices/customers';
import manageTeam from './slices/manageTeam';

const rootReducer = combineReducers({
    popUp,
    analyticsCustomers,
    analyticsKpi,
    products,
    profileInfo,
    superUsers,
    customers,
    manageTeam,
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
