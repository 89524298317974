import {PayloadAction} from '@reduxjs/toolkit';
import {IPopUp} from '../../../api/popUp/types';
import createGenericSlice, {IGenericState} from '../createGenericSlice';
import {slicesNames} from '../constants';

const initialState = {
    severity: '',
    summary: '',
    detail: '',
    life: 0,
    sticky: '',
};

interface IPopUpState {
    severity: string;
    summary: string;
    detail: string;
    life: number;
    sticky: string;
}

const reducers = {
    setPopUp: (state: IGenericState<IPopUpState>, {payload}: PayloadAction<Partial<IPopUp>>) => {
        state.data.severity = payload.data?.severity;
        state.data.summary = payload.data?.summary;
        state.data.detail = payload.data?.detail;
        state.data.life = payload.data?.life;
        state.data.sticky = payload.data?.sticky;
    },
};

const popUpSlice = createGenericSlice<IPopUpState, typeof reducers>({
    name: slicesNames.popUp,
    initialState: {
        data: initialState,
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers,
    extraReducers: () => {
        // TODO create Common slice and use popUp reducer
        console.error(1);
    },
});

export const {setPopUp} = popUpSlice.actions;
export default popUpSlice.reducer;
