import React, {FC, useCallback, useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useParams} from 'react-router-dom';
import Menu from '../../Common/Menu/Menu';
import {useAppDispatch} from '../../../hooks/redux';
import {fetchCustomer} from '../../../store/slices/customers/thunks';
import {LINKS} from '../../../globalConstants';

const CustomerMenu: FC = () => {
    const {id} = useParams();
    const productLink = `${LINKS.CUSTOMERS}/${id}`;

    const {logout, getAccessTokenSilently} = useAuth0();
    const dispatch = useAppDispatch();

    const menuLinks = [
        {link: productLink, value: 'Company Information'},
        {link: `${productLink}${LINKS.BILLING_DETAILS}`, value: 'Billing Details'},
        {link: `${productLink}${LINKS.BILLING_HISTORY}`, value: 'Billing History'},
        {link: `${productLink}${LINKS.SUBSCRIPTIONS}`, value: 'Subscriptions'},
    ];

    const fetchSelectedCustomer = useCallback(() => {
        const actionData = {
            params: {},
            id: +id,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };

        dispatch(fetchCustomer(actionData));
    }, [id, logout, getAccessTokenSilently, dispatch]);

    useEffect(() => {
        fetchSelectedCustomer();
    }, [fetchSelectedCustomer]);

    return <Menu items={menuLinks} />;
};

export default CustomerMenu;
