import React, {FC} from 'react';
import {Button} from 'primereact/button';
import styles from './FilterControls.module.scss';

interface IProps {
    applyFilters: any;
    resetFilters: any;
}

const FilterControls: FC<IProps> = ({applyFilters, resetFilters}) => {
    return (
        <div className={styles.filterControls}>
            <div className={styles.labelText}>Filters</div>
            <div className={styles.filterButtons}>
                <Button label="Apply" className="p-button-raised" onClick={applyFilters} />
                <Button label="Clear All" className="p-button-secondary p-button-raised" onClick={resetFilters} />
            </div>
        </div>
    );
};

export default FilterControls;
