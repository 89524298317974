import {createAsyncThunk} from '@reduxjs/toolkit';
import {IActionData} from '../../../apiRequestService/types';
import {slicesNames} from '../constants';
import {profileInfoThunkNames} from './constants';
import {setPopUp} from '../popUp/popUpSlice';
import {defaultErrorMessage, popUpInfo} from '../../../globalConstants';
import {getRoleByPermissions} from '../../../components/Common/utils';
import {getProfileInfo} from '../../../api/profileInfo';

export const fetchProfileInfo = createAsyncThunk(
    `${slicesNames.profileInfo}/${profileInfoThunkNames.fetchProfileInfo}`,
    async (data: IActionData, {dispatch}) => {
        try {
            const response = await getProfileInfo(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const savePermissions = createAsyncThunk(
    `${slicesNames.profileInfo}/${profileInfoThunkNames.savePermissions}`,
    async (data: []) => {
        return getRoleByPermissions(data);
    },
);
