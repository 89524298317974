import React, {FC, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAuth0} from '@auth0/auth0-react';
import moment from 'moment';
import AnalyticCustomersList from '../AnalyticCustomersList/AnalyticCustomersList';
import AnalyticCustomersFilters from '../AnalyticCustomersFilters/AnalyticCustomersFilters';
import {initialParams, dateOptions, subscriptionOptions} from '../../constants';
import {getExportURL} from '../../../Common/utils';
import {getMappedResponse} from '../../utils';
import LeftBar from '../../../LeftBar/LeftBar';
import {useAppDispatch} from '../../../../hooks/redux';
import {fetchAnalyticsCustomers} from '../../../../store/slices/analyticsCustomers/thunks';
import {selectAnalyticsCustomers, selectTotalRecords} from '../../../../store/slices/analyticsCustomers/selectors';
import {sendRequest, urls} from '../../../../apiRequestService';
import {dateFormatForSave, dateWithFullTimeFormat, requestMethod, responseType} from '../../../../globalConstants';
import {IACustomersParams, ICustomersFilters, ICustomersSubscription, IFromTo} from '../../types';
import styles from './AnalyticCustomersPage.module.scss';

const AnalyticCustomersPage: FC = () => {
    const [filters, setFilters] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [customersParams, setCustomersParams] = useState(initialParams);

    const {logout, getAccessTokenSilently} = useAuth0();

    const selectedCustomers = useSelector(selectAnalyticsCustomers);
    const totalRecords = useSelector(selectTotalRecords);

    const dispatch = useAppDispatch();

    const onApplyNewFilters = useCallback(
        (
            selectedRenewal: IFromTo,
            selectedInactive: Date[] | null[],
            selectedSubscriptions: ICustomersSubscription[],
        ): void => {
            setFilters({
                renewal: selectedRenewal ? selectedRenewal : null,
                inactive: selectedInactive
                    ? {
                          from: moment(selectedInactive[0]).format(dateFormatForSave),
                          ...(selectedInactive[1] && {
                              to: moment(selectedInactive[1]).format(dateFormatForSave),
                          }),
                      }
                    : null,
                names: selectedSubscriptions
                    ? selectedSubscriptions.map((item: ICustomersSubscription | null) => item.name)
                    : null,
            });
        },
        [],
    );

    const updateFilters = useCallback((newFilters: ICustomersFilters): (() => void) => {
        if (!newFilters) {
            return null;
        } else {
            const newRenewal = newFilters?.renewal ? newFilters?.renewal : null;
            const newInactive = newFilters?.inactive ? newFilters?.inactive : null;
            const newSubscription = newFilters?.names ? newFilters?.names : null;
            setCustomersParams((oldParams: IACustomersParams) => {
                const {navigation} = oldParams;
                const newQuery = {
                    subscription: {
                        ...(newRenewal && {renewalDate: newRenewal}),
                        ...(newInactive && {inactiveDate: newInactive}),
                        ...(newSubscription && {names: newSubscription}),
                    },
                };
                return {navigation: navigation, query: newQuery};
            });
        }
    }, []);

    const exportCustomers = useCallback(async () => {
        const actionData = {
            params: {},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };
        const response = await sendRequest(
            urls.ANALYTIC_COMPANY_EXCEL,
            requestMethod.GET,
            responseType.BLOB,
            actionData,
        );
        if (response) {
            const contentType = response.headers['content-type'];
            const contentDisposition = response.headers['content-disposition'];
            const dayToday = moment().format(dateWithFullTimeFormat.US);
            const fileName = contentDisposition?.slice(contentDisposition.indexOf('=') + 1) || `${dayToday}.zip`;
            const link = document.createElement('a');
            link.href = getExportURL([response.data], {type: contentType});
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }
    }, [getAccessTokenSilently, logout]);

    const fetchCustomers = useCallback(() => {
        const actionData = {
            params: customersParams,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };

        dispatch(fetchAnalyticsCustomers(actionData));
    }, [dispatch, getAccessTokenSilently, logout, customersParams]);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers, customersParams]);

    useEffect(() => {
        if (selectedCustomers) {
            setCustomers(getMappedResponse(selectedCustomers));
        }
    }, [selectedCustomers]);

    useEffect(() => {
        updateFilters(filters);
    }, [filters, updateFilters]);

    return (
        <div className={styles.analyticCustomerPage}>
            <LeftBar>
                <AnalyticCustomersFilters
                    dateOptions={dateOptions}
                    subscriptionOptions={subscriptionOptions}
                    applyNewFilters={onApplyNewFilters}
                />
            </LeftBar>
            <AnalyticCustomersList
                customers={customers}
                customersParams={customersParams}
                totalRecords={totalRecords}
                setCustomersParams={setCustomersParams}
                exportCustomers={exportCustomers}
                logout={logout}
                getAccessTokenSilently={getAccessTokenSilently}
                fetchCustomers={fetchCustomers}
            />
        </div>
    );
};

export default AnalyticCustomersPage;
