import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import classNames from 'classnames';
import {selectLoading} from '../../../store/slices/selectors';
import {slicesNames} from '../../../store/slices/constants';
import {productThunkNames} from '../../../store/slices/products/constants';
import {IProduct} from '../../../api/products/types';
import Footer from '../../Common/Footer/Footer';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import {urls} from '../../../apiRequestService';
import {IProductProps} from '../types';
import {IPagination} from '../../../globalTypes';
import styles from './ProductsList.module.scss';

const ProductsList: FC<IProductProps> = ({products, setProductsParams, productsParams, totalRecords}) => {
    const [pagination, setPagination] = useState({first: 0, rows: 20});

    const tableRef = useRef(null);
    const navigate = useNavigate();

    const isLoading = useSelector(selectLoading(slicesNames.products, productThunkNames.fetchProducts));

    const breadCrumbItems = useMemo(() => [{label: 'Products'}], []);

    const onPageSelect = useCallback(
        (pagination: IPagination) => {
            setPagination({first: pagination.first, rows: pagination.rows});
            setProductsParams(() => {
                return {
                    query: {},
                    navigation: {
                        sort: [
                            {
                                key: 'name',
                                order: 'ASC',
                            },
                        ],
                        page: {from: pagination.first, size: pagination.rows},
                    },
                };
            });
        },
        [setProductsParams],
    );

    const nameBodyTemplate = useCallback((rowData: IProduct) => {
        return <div className={styles.linkStyle}>{rowData.name}</div>;
    }, []);

    const onProductSelect = useCallback(
        (event: any) => {
            const productName = event.rowData.name;

            navigate(`/${urls.PRODUCTS}/${productName}`);
        },
        [navigate],
    );

    return (
        <div className={classNames(styles.productsList, 'list-generic')}>
            <BreadCrumb items={breadCrumbItems} />
            <DataTable
                ref={tableRef}
                className="table-generic p-datatable-sm"
                reorderableColumns
                value={products}
                resizableColumns
                columnResizeMode="expand"
                selectionMode="single"
                cellSelection
                onCellSelect={onProductSelect}
                dataKey="id"
                scrollable={true}
                emptyMessage=""
                loading={isLoading}>
                <Column
                    className="p-text-nowrap p-text-truncate"
                    field="name"
                    columnKey="name"
                    header="Name"
                    body={nameBodyTemplate}
                    headerStyle={{width: '270px', height: '48px'}}
                    bodyStyle={{height: '50px'}}
                    frozen
                />
                <Column
                    field="versionNumber"
                    columnKey="versionNumber"
                    header="Version"
                    headerStyle={{height: '48px', width: '150px'}}
                    bodyStyle={{height: '50px'}}
                />
                <Column
                    field="updateDatetime"
                    columnKey="updateDatetime"
                    header="Updated Date"
                    headerStyle={{width: '150px'}}
                />
                <Column
                    field="signInUserCount"
                    columnKey="signInUserCount"
                    header="Signed In"
                    headerStyle={{width: '150px'}}
                />
            </DataTable>
            <Footer
                totalRecords={totalRecords}
                initParams={{}}
                params={productsParams}
                pagination={pagination}
                type="product"
                onPageSelect={onPageSelect}
            />
        </div>
    );
};

export default ProductsList;
