import React, {FC} from 'react';
import isEqual from 'lodash/isEqual';
import {Paginator} from 'primereact/paginator';
import {getNoFiltersMessage, getNoEntriesMessage} from './constants';
import {IPagination, IParams} from '../../../globalTypes';

interface IProps {
    totalRecords: number | null;
    initParams: Record<string, unknown>;
    params: IParams;
    type: string;
    pagination: IPagination;
    onPageSelect: (pagination: {first: number; page: number; pageCount: number; rows: number}) => void;
}

const Footer: FC<IProps> = ({totalRecords, initParams, params, type, pagination, onPageSelect}) => {
    if (!totalRecords) {
        const emptyMessage = isEqual(initParams, params) ? getNoEntriesMessage(type) : getNoFiltersMessage(type);

        return (
            <div className="generic-list-message">
                <h3 className="p-text-center">{emptyMessage}</h3>
            </div>
        );
    } else {
        return (
            <Paginator
                rows={pagination.rows}
                totalRecords={totalRecords}
                first={pagination.first}
                rowsPerPageOptions={[20, 50, 100]}
                template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink
                LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                onPageChange={onPageSelect}
            />
        );
    }
};

export default Footer;
