import React, {useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import classNames from 'classnames';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import {selectBillingDetails, selectCustomer} from '../../../../store/slices/customers/selectors';
import {fetchCustomerBillingDetails} from '../../../../store/slices/customers/thunks';
import {useAppDispatch} from '../../../../hooks/redux';
import {paymentLogoStyle, upperCaseToCapitalize} from '../../constants';
import {LINKS} from '../../../../globalConstants';
import styles from './BillingDetailsInfo.module.scss';

const BillingDetailInfo = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedCustomer = useSelector(selectCustomer);
    const {logout, getAccessTokenSilently} = useAuth0();

    const billingDetails = useSelector(selectBillingDetails);

    const breadCrumbItems = useMemo(
        () => [
            {
                label: 'Customers',
                command: () => {
                    navigate(LINKS.CUSTOMERS);
                },
            },
            {
                label: selectedCustomer.name,
                command: () => {
                    navigate(`${LINKS.CUSTOMERS}/${id}`);
                },
            },
            {label: 'Billing Details'},
        ],
        [id, navigate, selectedCustomer.name],
    );

    const fetchBillingDetails = useCallback(() => {
        const actionData = {
            params: {},
            id: +id,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };

        dispatch(fetchCustomerBillingDetails(actionData));
    }, [dispatch, getAccessTokenSilently, logout, id]);

    useEffect(() => {
        selectedCustomer && fetchBillingDetails();
    }, [fetchBillingDetails, selectedCustomer]);

    return (
        <div className={classNames('pageWrapper', styles.billingDetails)}>
            <BreadCrumb items={breadCrumbItems} />
            <div className={styles.infoWrapper}>
                <div className={styles.item}>
                    Billing Period: {upperCaseToCapitalize(billingDetails?.subscription?.billingPeriod)}
                </div>
                <div className={styles.item}>
                    <p>Payment Method</p>
                    <div className={styles.card}>
                        <div
                            className={classNames(
                                styles.imageWrapper,
                                styles[paymentLogoStyle[billingDetails?.card?.type]],
                            )}
                        />
                        <div className={styles.info}>
                            <div className={styles.simpleText}>
                                {billingDetails?.card?.hiddenCardNumber || 'Card number not specified'}
                            </div>
                            <div className={styles.simpleText}>
                                {billingDetails?.card?.cardholderName || 'Card holder not specified'}
                            </div>
                            <div className={styles.simpleText}>
                                {billingDetails?.card?.expirationDate || 'Expiration date not specified'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.item}>
                    {' '}
                    Billing Contact Email: <span className={styles.simpleText}>{billingDetails?.customer?.email}</span>
                </div>
            </div>
        </div>
    );
};

export default BillingDetailInfo;
