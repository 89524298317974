export const manageTeamThunkNames = {
    fetchUsers: 'fetchUsers',
    fetchUser: 'fetchUser',
    createUser: 'createSuperUser',
    updateUser: 'updateSuperuser',
    deleteUser: 'deleteUser',
    getRoles: 'getRoles',
    getProducts: 'getProducts',
};

export const defaultSelectedUser = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    roles: [
        {
            name: '',
            type: '',
            product: {
                name: '',
                fullName: '',
            },
        },
    ],
};

export const defaultRoles = [
    {
        name: '',
        type: '',
        product: {
            name: '',
            fullName: '',
        },
    },
];

export const defaultPage = {
    from: 0,
    size: 20,
    total: 0,
};
