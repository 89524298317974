const initialParams = {
    query: {},
    navigation: {
        sort: [
            {
                key: 'companyName',
                order: 'ASC',
            },
        ],
        page: {
            from: 0,
            size: 20,
        },
    },
};

const initialProductParams = {
    query: {},
    navigation: {
        sort: [
            {
                key: 'tradeName',
                order: 'ASC',
            },
        ],
        page: {
            from: 0,
            size: 20,
        },
    },
};

const dateOptions = [
    {
        id: 1,
        name: '7 days',
    },
    {
        id: 2,
        name: '30 days',
    },
    {
        id: 3,
        name: '90 days',
    },
];

const subscriptionOptions = [
    {
        id: 1,
        name: 'Trials Essentials',
    },
    {
        id: 2,
        name: 'Trials Collaborate',
    },
    {
        id: 3,
        name: 'Trials Enterprise',
    },
];

const headers = {
    REVENUE_GROWTH: 'Revenue Growth',
    REVENUE: 'Revenue',
    CHURN_REVENUE: 'Churn Revenue',
    NEW_TRIAL_SUBSCRIPTIONS: 'New Trial Subscriptions',
    NEW_CUSTOMER_SUBSCRIPTIONS: 'New Customer Subscriptions',
    CUSTOMER_CHURN: 'Customer Churn',
    SITE_MRR: 'Site MRR',
    SITE_ARR: 'Site ARR',
    CURRENT_CUSTOMERS: 'Current Customers',
    NEW_SUBSCRIPTIONS: 'New Subscriptions',
};
const titles = {
    VS_PREVIOUS_MONTH: 'vs. Previous Month',
    TOTAL_12_MONTHS: 'Total 12 Months',
    YDT: 'YDT',
    TRIAL: 'Trial',
    PAID: 'Paid',
};

const optionsMRR = {
    responsive: true,
    aspectRatio: 2.5,
    plugins: {
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        title: {
            display: false,
        },
        legend: {
            display: false,
            position: 'bottom',
        },
    },
    scales: {
        y: {
            ticks: {
                callback: function (value: string) {
                    return '$' + value;
                },
            },
        },
    },
    tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
            label: function (_tooltipItems: string | string[] | undefined, data: Record<string, unknown>) {
                return data + 'g';
            },
        },
    },
};

const optionsARR = {
    responsive: true,
    aspectRatio: 1.5,
    plugins: {
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        title: {
            display: false,
        },
        legend: {
            display: false,
            position: 'bottom',
        },
    },
    scales: {
        y: {
            ticks: {
                callback: function (value: string) {
                    return '$' + value;
                },
            },
        },
    },
    tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
            label: function (_tooltipItems: string | string[] | undefined, data: Record<string, unknown>) {
                return data + 'g';
            },
        },
    },
};

const optionsChurn = {
    responsive: true,
    aspectRatio: 2.5,
    plugins: {
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        title: {
            display: false,
        },
        legend: {
            display: false,
            position: 'bottom',
        },
    },
    tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
            label: function (_tooltipItems: string | string[] | undefined, data: Record<string, unknown>) {
                return data + 'g';
            },
        },
    },
};

const optionsPie = {
    responsive: true,
    aspectRatio: 1.5,
    plugins: {
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'top',
        },
    },
};

const optionsStacked = {
    responsive: true,
    aspectRatio: 2.5,
    plugins: {
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'top',
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
    tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
            label: function (_tooltipItems: string | string[] | undefined, data: Record<string, unknown>) {
                return data + 'g';
            },
        },
    },
};

const responseStatus = {
    FINISHED: 'FINISHED',
    IN_PROGRESS: 'IN_PROGRESS',
};

const initialChartData = {
    datasets: [
        {
            backgroundColor: [''],
            data: [0],
        },
    ],
    labels: [''],
};

export {
    initialParams,
    initialProductParams,
    dateOptions,
    subscriptionOptions,
    headers,
    titles,
    optionsMRR,
    optionsARR,
    optionsChurn,
    optionsStacked,
    optionsPie,
    responseStatus,
    initialChartData,
};
