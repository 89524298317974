import React from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import AppRoutes from './appRoutes';
import Navigation from './components/Navigation/Navigation';
import './App.scss';

const App: React.FC = () => {
    return (
        <div className="app container py-3">
            <Navigation />
            <AppRoutes />
        </div>
    );
};

export default App;
