import React, {FC} from 'react';
import MenuDivider from './MenuDivider';
import {NavLink} from 'react-router-dom';
import styles from './Menu.module.scss';

interface IItem {
    link: string;
    value: string;
    onClick?: () => void;
}

interface IProps {
    name?: string;
    items: IItem[];
}

const Menu: FC<IProps> = ({name, items}) => {
    return (
        <div className={styles.menu}>
            <ul className={styles.menuItems} role="menu">
                {items.map(({link, value, onClick}, index) => (
                    <div key={index}>
                        <li key={index}>
                            <NavLink
                                to={{
                                    pathname: link,
                                }}
                                state={name || ''}
                                className={({isActive}) => (isActive ? styles.active : undefined)}
                                onClick={onClick}>
                                {value}
                            </NavLink>
                        </li>
                        <MenuDivider />
                    </div>
                ))}
            </ul>
        </div>
    );
};

export default Menu;
