import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {DataTable, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import classNames from 'classnames';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import Footer from '../../Common/Footer/Footer';
import CustomDialog from '../../Common/CustomDialog/CustomDialog';
import ManageTeamForm from '../ManageTeamForm/ManageTeamForm';
import SearchInput from '../../Common/SearchInput/SearchInput';
import {useAppDispatch} from '../../../hooks/redux';
import {selectLoading} from '../../../store/slices/selectors';
import {manageTeamThunkNames} from '../../../store/slices/manageTeam/constants';
import {removeUser, fetchUsers} from '../../../store/slices/manageTeam/thunks';
import {slicesNames} from '../../../store/slices/constants';
import {initialUsersParams} from '../constants';
import {IPagination, ITableSort} from '../../../globalTypes';
import {IMappedUser, IRole, IUser} from '../../../api/manageTeam/types';
import {IManageTeamsListProps} from '../types';
import {getMappedUsers} from '../utils';
import styles from './ManageTeamList.module.scss';

const ManageTeamList: FC<IManageTeamsListProps> = ({
    users,
    usersParams,
    setUsersParams,
    totalRecords,
    logout,
    getAccessTokenSilently,
    actionData,
}) => {
    const [mappedUsers, setMappedUsers] = useState([]);
    const [sort, setSort] = useState<ITableSort>({field: 'name', order: 1});
    const [pagination, setPagination] = useState({first: 0, rows: 20});
    const [displayUserModal, setDisplayUserModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const tableRef = useRef(null);
    const dispatch = useAppDispatch();
    const isLoading = useSelector(selectLoading(slicesNames.manageTeam, manageTeamThunkNames.fetchUsers));

    const breadCrumbItems = useMemo(() => [{label: 'Manage Team'}], []);

    const actionDeleteData = useMemo(
        () => ({
            params: {
                ids: [userToDelete?.id],
            },
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [getAccessTokenSilently, logout, userToDelete?.id],
    );

    const onNewUserCreate = useCallback(() => {
        setDisplayUserModal(true);
    }, []);

    const onSort = useCallback(
        ({sortField, sortOrder}: {sortField: string; sortOrder: DataTableSortOrderType}) => {
            setSort({field: sortField, order: sortOrder});
            setUsersParams((oldParams) => {
                const {query} = oldParams;
                return {
                    query: query,
                    navigation: {
                        page: {from: 0, size: 20},
                        sort: [{key: sortField, order: sortOrder === 1 ? 'ASC' : 'DESC'}],
                    },
                };
            });
        },
        [setUsersParams],
    );

    const header = useMemo(
        () => (
            <div className={styles.tableHeader}>
                <Button
                    className="p-button-raised"
                    label="New User"
                    icon="pi pi-plus"
                    onClick={() => onNewUserCreate()}
                />
                <SearchInput setParams={setUsersParams} />
            </div>
        ),
        [onNewUserCreate, setUsersParams],
    );

    const onUserSelect = useCallback((user: IMappedUser) => {
        setSelectedUser(user);
        setDisplayUserModal(true);
    }, []);

    const firstNameBodyTemplate = useCallback(
        (rowData: IMappedUser) => {
            return (
                <button className={styles.linkStyle} onClick={() => onUserSelect(rowData)}>
                    {rowData.userFirstName}
                </button>
            );
        },
        [onUserSelect],
    );

    const lastNameBodyTemplate = useCallback(
        (rowData: IMappedUser) => {
            return (
                <button className={styles.linkStyle} onClick={() => onUserSelect(rowData)}>
                    {rowData.userLastName}
                </button>
            );
        },
        [onUserSelect],
    );

    const rolesBodyTemplate = useCallback((rowData: IUser) => {
        return rowData.roles?.map((role: IRole) => role.name).join(', ');
    }, []);

    const removeBodyTemplate = useCallback((rowData: IUser) => {
        return (
            <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-warning"
                onClick={() => setUserToDelete(rowData)}
            />
        );
    }, []);

    const hideDeleteUserDialog = useCallback(() => {
        setUserToDelete(null);
    }, []);

    const deleteUser = useCallback(() => {
        dispatch(removeUser(actionDeleteData))
            .then(() => {
                dispatch(fetchUsers(actionData));
            })
            .finally(() => {
                setUserToDelete(null);
            });
    }, [actionData, actionDeleteData, dispatch]);

    const onPageSelect = useCallback(
        (pagination: IPagination) => {
            setPagination({first: pagination.first, rows: pagination.rows});
            setUsersParams(() => {
                return {
                    query: {},
                    navigation: {
                        sort: [
                            {
                                key: 'userFirstName',
                                order: 'ASC',
                            },
                        ],
                        page: {from: pagination.first, size: pagination.rows},
                    },
                };
            });
        },
        [setUsersParams],
    );

    useEffect(() => {
        if (users) {
            setMappedUsers(getMappedUsers(users));
        }
    }, [users]);

    return (
        <div className={classNames(styles.manageTeamList, 'list-generic')}>
            {displayUserModal && (
                <ManageTeamForm
                    displayUserModal={displayUserModal}
                    setDisplayUserModal={setDisplayUserModal}
                    setSelectedUser={setSelectedUser}
                    userId={selectedUser?.id}
                    logout={logout}
                    getAccessTokenSilently={getAccessTokenSilently}
                    usersActionData={actionData}
                />
            )}
            <BreadCrumb items={breadCrumbItems} />
            <DataTable
                ref={tableRef}
                className="table-generic table-with-header p-datatable-sm"
                value={mappedUsers}
                header={header}
                resizableColumns
                reorderableColumns
                columnResizeMode="expand"
                selectionMode="single"
                cellSelection
                dataKey="id"
                sortField={sort.field}
                sortOrder={sort.order}
                onSort={onSort}
                scrollable={true}
                emptyMessage=""
                loading={isLoading}>
                <Column
                    className="p-text-nowrap p-text-truncate"
                    field="userFirstName"
                    columnKey="userFirstName"
                    header="First Name"
                    headerStyle={{width: '270px', height: '48px'}}
                    bodyStyle={{height: '50px'}}
                    body={firstNameBodyTemplate}
                    sortable
                    sortFunction={() => mappedUsers}
                />
                <Column
                    field="userLastName"
                    columnKey="userLastName"
                    header="Last Name"
                    headerStyle={{height: '48px', width: '150px'}}
                    body={lastNameBodyTemplate}
                    sortFunction={() => mappedUsers}
                    sortable
                />
                <Column
                    field="userEmail"
                    columnKey="userEmail"
                    header="Email"
                    headerStyle={{height: '48px', width: '150px'}}
                    sortable
                />
                <Column
                    field="roles"
                    columnKey="roles"
                    header="Roles"
                    body={rolesBodyTemplate}
                    headerStyle={{height: '48px', width: '150px'}}
                />
                <Column field="remove" header="Remove" body={removeBodyTemplate} headerStyle={{width: '70px'}} />
            </DataTable>
            <Footer
                totalRecords={totalRecords}
                initParams={initialUsersParams}
                params={usersParams}
                pagination={pagination}
                type="employee"
                onPageSelect={onPageSelect}
            />
            <CustomDialog
                visible={userToDelete}
                header="Delete Confirmation"
                footerConfirmHandler={deleteUser}
                onHideHandler={hideDeleteUserDialog}
                message="Are you sure you want to delete user"
                name={`${userToDelete?.userFirstName} ${userToDelete?.userLastName}`}
            />
        </div>
    );
};

export default ManageTeamList;
