import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {ERROR_CODE, ERROR_TEXT, LINK_TEXT} from './appRoutesConstants';
import {LINKS} from '../globalConstants';
import ProductsPage from '../components/Products/ProductsPage/ProductsPage';
import ProductHomePage from '../components/Products/ProductHomePage/ProductHomePage';
import CustomersPage from '../components/Customers/CustomersPage/CustomersPage';
import ManageTeam from '../components/ManageTeam/ManageTeamPage/MangeTeamPage';
import AnalyticKPIPage from '../components/Analytic/AnalyticKPI/AnalyticKPIPage/AnalyticKPIPage';
import AnalyticCustomersPage from '../components/Analytic/AnalyticCustomers/AnalitycCustomersPage/AnalyticCustomersPage';
import SuperUsersPage from '../components/SuperUsers/SuperUsersPage/SuperUsersPage';
import GenericErrorPage from '../components/Common/GenericErrorPage';
import ProtectedComponent from '../auth/protected-component';
import HomePage from '../components/HomePage/HomePage';
import CustomerInfo from '../components/Customers/CustomerInfo/CustomerInfo';
import BillingDetailsPage from '../components/Customers/BillingDetals/BillingDetailsPage/BillingDetailsPage';
import BillingHistoryPage from '../components/Customers/BillingHistory/BillingHistoryPage/BillingHistoryPage';
import SubscriptionsPage from '../components/Customers/Subscriptions/SubscriptionsPage/SubscriptionsPage';

const AppRoutes = () => {
    return (
        <div className="content">
            <Routes>
                <Route path={LINKS.PRODUCTS} element={<ProtectedComponent component={ProductsPage} />} />
                <Route path={LINKS.CUSTOMERS} element={<ProtectedComponent component={CustomersPage} />} />
                <Route path={LINKS.CUSTOMER} element={<ProtectedComponent component={CustomerInfo} />} />
                <Route path={LINKS.MANAGE_TEAM} element={<ProtectedComponent component={ManageTeam} />} />
                <Route path={LINKS.PRODUCT} element={<ProtectedComponent component={ProductHomePage} />} />
                <Route path={LINKS.PRODUCT_SYSTEM} element={<ProtectedComponent component={ProductHomePage} />} />
                <Route path={LINKS.PRODUCT_KPI} element={<ProtectedComponent component={AnalyticKPIPage} />} />
                <Route
                    path={LINKS.PRODUCT_CUSTOMERS}
                    element={<ProtectedComponent component={AnalyticCustomersPage} />}
                />
                <Route path={LINKS.SUPER_USER} element={<ProtectedComponent component={SuperUsersPage} />} />
                <Route
                    path={LINKS.CUSTOMER_BILLING_DETAILS}
                    element={<ProtectedComponent component={BillingDetailsPage} />}
                />
                <Route
                    path={LINKS.CUSTOMER_BILLING_HISTORY}
                    element={<ProtectedComponent component={BillingHistoryPage} />}
                />
                <Route
                    path={LINKS.CUSTOMER_SUBSCRIPTIONS}
                    element={<ProtectedComponent component={SubscriptionsPage} />}
                />
                <Route path={LINKS.HOME} element={<HomePage />} />
                <Route
                    path="*"
                    element={
                        <GenericErrorPage
                            errorCode={ERROR_CODE}
                            errorText={ERROR_TEXT}
                            linkTo={LINKS.HOME}
                            linkText={LINK_TEXT}
                        />
                    }
                />
            </Routes>
        </div>
    );
};

export default AppRoutes;
