export const initialProductParams = {
    navigation: {
        sort: [
            {
                key: 'name',
                order: 'ASC',
            },
        ],
        page: {
            from: 0,
            size: 20,
        },
    },
    query: {},
};
