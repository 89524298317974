import {sendRequest, urls} from '../../apiRequestService';
import {IActionData} from '../../apiRequestService/types';
import {requestMethod, responseType} from '../../globalConstants';
import {IGetSuperUsersCompData} from './types';

export const getSuperUsers = (data: IGetSuperUsersCompData) => {
    return sendRequest(urls.SUPER_USERS_COMPANIES, requestMethod.POST, responseType.JSON, data);
};

export const deleteUser = (data: IActionData) => {
    return sendRequest(`${urls.SUPER_USERS}/${data.id}`, requestMethod.DELETE, responseType.JSON, data);
};

export const createUser = (data: IActionData) => {
    return sendRequest(urls.SUPER_USERS, requestMethod.POST, responseType.JSON, data);
};
