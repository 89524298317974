import React from 'react';
import LeftBar from '../../../LeftBar/LeftBar';
import CustomerMenu from '../../CustomerMenu/CustomerMenu';
import SubscriptionsInfo from '../SubscriptionsInfo/SubscriptionsInfo';

const SubscriptionsPage = () => {
    return (
        <>
            <LeftBar>
                <CustomerMenu />
            </LeftBar>
            <SubscriptionsInfo />
        </>
    );
};

export default SubscriptionsPage;
