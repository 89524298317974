import {fetchAnalyticsKpi} from './thunks';
import createGenericSlice from '../createGenericSlice';
import {slicesNames} from '../constants';

export const analyticsKpiSlice = createGenericSlice({
    name: slicesNames.analyticsKpi,
    initialState: {
        data: {
            lastUpdateDate: '',
            finance: {
                arr: [],
                mrr: [],
                revenue: null,
                subscription: null,
            },
            company: {
                customer: null,
                products: null,
            },
        },
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAnalyticsKpi.fulfilled, (state, {payload}) => {
            if (payload) state.data = payload;
        });
    },
});

export const {resetSlice} = analyticsKpiSlice.actions;
