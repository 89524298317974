import moment from 'moment';
import {ICustomer} from '../../api/analyticsCustomers/types';
import {
    IMonthAmount,
    IArr,
    ICompany,
    IProductInfo,
    ICanceledMonth,
    IPaidMonth,
    IKpi,
} from '../../api/analyticsKPI/types';
import {initialChartData} from './constants';
import {dateFormat, dateFormatForSave, dateWithFullTimeFormat} from '../../globalConstants';

const getMappedResponse = (customers: ICustomer[]) => {
    return customers?.map((customer: ICustomer) => {
        return {
            id: customer.company.id,
            companyName: customer.company.name,
            employeeCount: customer.company.employeeCount,
            companyStartDate: customer.company?.createDate
                ? moment(customer.company?.createDate).format(dateFormat.US)
                : '',
            lastLoginDate: customer.company?.lastLoginDate
                ? moment(customer.company?.lastLoginDate).format(dateFormat.US)
                : '',
            subscriptionName: customer.subscription.name,
            subscriptionRenewalDate: customer.subscription?.renewalDate
                ? moment(customer.subscription?.renewalDate).format(dateFormat.US)
                : '',
            trialWeekCount: customer.trialCount.week,
            trialMonthCount: customer.trialCount.month,
            trialQuarterCount: customer.trialCount.quarter,
            trialYearCount: customer.trialCount.year,
            totalTrialCount: customer.trialCount.totalCount,
            plantWeekCount: customer.plantCount.week,
            plantMonthCount: customer.plantCount.month,
            plantQuarterCount: customer.plantCount.quarter,
            plantYearCount: customer.plantCount.year,
            totalPlantCount: customer.plantCount.totalCount,
            trialLogWeekCount: customer.trialLogCount.week,
            trialLogMonthCount: customer.trialLogCount.month,
            trialLogQuarterCount: customer.trialLogCount.quarter,
            trialLogYearCount: customer.trialLogCount.year,
            trialLogWeekAvg: customer.trialLogAvg.week,
        };
    });
};

const getSiteMRR = (response: IMonthAmount[]) => {
    return {
        labels: response?.map((data: IMonthAmount) => data.month.substring(0, 3)),
        datasets: [
            {
                data: response?.map((data: IMonthAmount) => data.amount),
                backgroundColor: ['#a62884'],
            },
        ],
    };
};

const getSiteARR = (response: IArr[]) => {
    return {
        labels: response?.map((data: IArr) => data.year),
        datasets: [
            {
                data: response?.map((data: IArr) => data.amount),
                backgroundColor: ['#28d1da'],
            },
        ],
    };
};

const getCurrentCustomers = (response: ICompany) => {
    return {
        labels: response?.products?.productInfo.map((data: IProductInfo) => data.name.substring(7)),
        datasets: [
            {
                data: response?.products?.productInfo.map((data: IProductInfo) => data.count),
                backgroundColor: ['#e400b3', '#7d0c68', '#d58bd3'],
                hoverOffset: 4,
            },
        ],
    };
};

const getCustomerChurn = (response: ICanceledMonth[]) => {
    return {
        labels: response?.map((data: ICanceledMonth) => data.month.substring(0, 3)),
        datasets: [
            {
                data: response?.map((data: ICanceledMonth) => data.count),
                backgroundColor: ['#a62884'],
            },
        ],
    };
};

const getTrialAndPaidSubCount = (response: IPaidMonth[]) => {
    return {
        labels: response?.map((data: IPaidMonth) => data.month.substring(0, 3)),
        datasets: [
            {
                label: 'Trial',
                data: response?.map((data: IPaidMonth) => data.newTrialSubCount),
                backgroundColor: ['#28a64c'],
            },
            {
                label: 'Paid',
                data: response?.map((data: IPaidMonth) => data.newPaidSubCount),
                backgroundColor: ['#134d2f'],
            },
        ],
    };
};

const getAnalyticData = (data: IKpi) => {
    const revenueGrowth = data.finance.revenue
        ? {
              previousMonth: data.finance.revenue.growthRate?.recentMonths?.rate,
              total12Month: data.finance.revenue.growthRate?.last12Month?.rate,
          }
        : {};
    const revenue = data.finance.revenue
        ? {
              ytd: data.finance.revenue.revenueAmount?.ytd?.amount,
              total12Month: data.finance.revenue.revenueAmount?.last12Month?.amount,
          }
        : {};
    const churnRevenue = data.finance.revenue
        ? {
              ytd: data.finance.revenue.churnAmount?.ytd?.amount,
              total12Month: data.finance.revenue.churnAmount?.last12Month?.amount,
          }
        : {};
    const siteMRR = data.finance.mrr.length ? getSiteMRR(data.finance.mrr[0].monthAmounts) : initialChartData;
    const siteARR = data.finance.arr.length ? getSiteARR(data.finance.arr) : initialChartData;
    const currentCustomers = data.company.customer ? getCurrentCustomers(data.company) : initialChartData;
    const totalCustomers = data.company.customer ? data.company.customer.count : 0;
    const newTrialSubscriptions = data.finance.subscription
        ? {
              previousMonth: data.finance.subscription.newTrialSubs?.recentMonths?.rate,
              total12Month: data.finance?.subscription?.newTrialSubs?.last12Month?.rate,
          }
        : {};
    const newCustomerSubscriptions = data.finance.subscription
        ? {
              previousMonth: data.finance?.subscription?.newPaidSubs?.recentMonths?.rate,
              total12Month: data.finance?.subscription?.newPaidSubs?.last12Month?.rate,
          }
        : {};
    const subscriptionChurn = data.finance.subscription
        ? {
              previousMonth: data.finance?.subscription?.subscriptionsChurn?.recentMonths?.rate,
              total12Month: data.finance?.subscription?.subscriptionsChurn?.last12Month?.rate,
          }
        : {};
    const canceledSubCount = data.finance.subscription
        ? getCustomerChurn(data.finance.subscription.canceledSubCount[0]?.monthCounts)
        : initialChartData;
    const trialAndPaidSubCount = data.finance.subscription
        ? getTrialAndPaidSubCount(data.finance?.subscription?.trialAndPaidSubCount[0]?.monthCounts)
        : initialChartData;
    const lastUpdate = moment(data.lastUpdateDate).format(dateWithFullTimeFormat.US);
    return {
        revenueGrowth: revenueGrowth,
        revenue: revenue,
        churnRevenue: churnRevenue,
        siteMRR: siteMRR,
        siteARR: siteARR,
        currentCustomers: currentCustomers,
        totalCustomers,
        newTrialSubscriptions: newTrialSubscriptions,
        newCustomerSubscriptions: newCustomerSubscriptions,
        subscriptionChurn: subscriptionChurn,
        trialAndPaidSubCount: trialAndPaidSubCount,
        canceledSubCount: canceledSubCount,
        lastUpdate: lastUpdate,
    };
};

const getFilteredDate = (data: any) => {
    if (!data) {
        return null;
    } else {
        const from = moment(data).format(dateFormatForSave);
        const to = moment(data).add(data.name.split(' ')[0], 'd').format(dateFormatForSave);
        return {to: to, from: from};
    }
};

export {
    getMappedResponse,
    getSiteARR,
    getCurrentCustomers,
    getCustomerChurn,
    getTrialAndPaidSubCount,
    getAnalyticData,
    getFilteredDate,
};
