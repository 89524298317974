import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import Footer from '../../../Common/Footer/Footer';
import {
    selectBillingHistory,
    selectBillingHistoryTotal,
    selectCustomer,
} from '../../../../store/slices/customers/selectors';
import {selectLoading} from '../../../../store/slices/selectors';
import {slicesNames} from '../../../../store/slices/constants';
import {customersThunkNames} from '../../../../store/slices/customers/constants';
import {IPagination} from '../../../../globalTypes';
import {IBillingHistoryProps, IBillingHistoryRowData} from '../../types';
import {dateFormat, LINKS} from '../../../../globalConstants';
import {getInitialBillingHistoryParams, upperCaseToCapitalize} from '../../constants';
import styles from './BilingHistoryList.module.scss';

const BillingHistoryList: FC<IBillingHistoryProps> = ({params, setParams}) => {
    const [pagination, setPagination] = useState({first: 0, rows: 20});

    const bills = useSelector(selectBillingHistory);
    const totalRecords = useSelector(selectBillingHistoryTotal);

    const {id} = useParams();

    const selectedCustomer = useSelector(selectCustomer);
    const isLoading = useSelector(
        selectLoading(slicesNames.customers, customersThunkNames.fetchCustomerBillingHistory),
    );

    const tableRef = useRef(null);
    const navigate = useNavigate();

    const breadCrumbItems = useMemo(
        () => [
            {
                label: 'Customers',
                command: () => {
                    navigate(LINKS.CUSTOMERS);
                },
            },
            {
                label: selectedCustomer.name,
                command: () => {
                    navigate(`${LINKS.CUSTOMERS}/${id}`);
                },
            },
            {label: 'Billing History'},
        ],
        [id, navigate, selectedCustomer.name],
    );

    const onPageSelect = useCallback(
        (pagination: IPagination) => {
            setPagination({first: pagination.first, rows: pagination.rows});
            setParams(() => {
                return {
                    query: {companyId: +1},
                    navigation: {
                        sort: [
                            {
                                key: 'name',
                                order: 'ASC',
                            },
                        ],
                        page: {from: pagination.first, size: pagination.rows},
                    },
                };
            });
        },
        [setParams],
    );

    const dataBodyTemplate = useCallback((rowData: IBillingHistoryRowData) => {
        return moment(rowData.createDate).format(dateFormat.US);
    }, []);

    const billingPeriodBodyTemplate = useCallback((rowData: IBillingHistoryRowData) => {
        return upperCaseToCapitalize(rowData.billingPeriod);
    }, []);

    const priceBodyTemplate = useCallback((rowData: IBillingHistoryRowData) => {
        return `$ ${rowData.price.toFixed(2)}`;
    }, []);

    const downloadBodyTemplate = useCallback((rowData: IBillingHistoryRowData) => {
        return !rowData.invoicePdfUrl ? (
            <a
                href={rowData.invoicePdfUrl}
                className={classNames(styles.downloadLink, styles.disabledLink)}
                target="_blank"
                rel="noreferrer"
                title="The invoice has not been finalized yet">
                <i className="pi pi-download" />
            </a>
        ) : (
            <a
                href={rowData.invoicePdfUrl}
                className={classNames(styles.downloadLink)}
                target="_blank"
                rel="noreferrer">
                <i className="pi pi-download" />
            </a>
        );
    }, []);

    return (
        <div className="pageWrapper">
            <div className={classNames(styles.billingHistoryList, 'list-generic')}>
                <BreadCrumb items={breadCrumbItems} />
                <DataTable
                    ref={tableRef}
                    className="table-generic p-datatable-sm"
                    value={bills}
                    reorderableColumns
                    resizableColumns
                    cellSelection
                    columnResizeMode="expand"
                    selectionMode="single"
                    dataKey="id"
                    scrollable={true}
                    emptyMessage=""
                    loading={isLoading}
                    frozenWidth="220px">
                    <Column
                        className="p-text-nowrap p-text-truncate"
                        field="createDate"
                        columnKey="createDate"
                        header="Date"
                        headerStyle={{width: '270px', height: '48px'}}
                        bodyStyle={{height: '50px'}}
                        body={dataBodyTemplate}
                    />
                    <Column
                        field="invoiceNumber"
                        columnKey="invoiceNumber"
                        header="Invoice Number"
                        headerStyle={{height: '48px', width: '150px'}}
                        bodyStyle={{height: '50px'}}
                    />
                    <Column
                        field="billingPeriod"
                        columnKey="billingPeriod"
                        header="Billing Period"
                        headerStyle={{height: '48px', width: '150px'}}
                        bodyStyle={{height: '50px'}}
                        body={billingPeriodBodyTemplate}
                    />
                    <Column
                        field="price"
                        columnKey="price"
                        header="Amount"
                        headerStyle={{height: '48px', width: '150px'}}
                        bodyStyle={{height: '50px'}}
                        body={priceBodyTemplate}
                    />
                    <Column
                        header="Download"
                        headerStyle={{height: '48px', width: '150px'}}
                        bodyStyle={{height: '50px'}}
                        body={downloadBodyTemplate}
                    />
                </DataTable>
                <Footer
                    totalRecords={totalRecords}
                    initParams={getInitialBillingHistoryParams(+1)}
                    params={params}
                    pagination={pagination}
                    type="bill"
                    onPageSelect={onPageSelect}
                />
            </div>
        </div>
    );
};

export default BillingHistoryList;
