import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAuth0} from '@auth0/auth0-react';
import ManageTeamList from '../ManageTeamList/ManageTeamList';
import {useAppDispatch} from '../../../hooks/redux';
import {fetchUsers} from '../../../store/slices/manageTeam/thunks';
import {selectTotalRecords, selectUsers} from '../../../store/slices/manageTeam/selectors';
import {initialUsersParams} from '../constants';
import {IFilterData, IManageTeamParams} from '../types';
import styles from './ManageTeamPage.module.scss';

const ManageTeamPage = () => {
    const [filters, setFilters] = useState(null);
    const [usersParams, setUsersParams] = useState(initialUsersParams);

    const users = useSelector(selectUsers);
    //ToDo uncomment when backend for filter will be ready

    // const productOptions = useSelector(selectProductOptions);
    const totalRecords = useSelector(selectTotalRecords);

    const dispatch = useAppDispatch();
    const {logout, getAccessTokenSilently} = useAuth0();

    //ToDo uncomment when backend for filter will be ready

    // const onApplyNewFilters = useCallback((selectedProduct: IFilterOption[]): void => {
    //     setFilters({
    //         products: selectedProduct ? selectedProduct : null,
    //     });
    // }, []);

    const updateFilters = useCallback((newFilters: IFilterData): (() => void) => {
        if (!newFilters) {
            return null;
        } else {
            const newProduct = newFilters?.products ? newFilters?.products : null;
            setUsersParams((oldParams: IManageTeamParams) => {
                const {navigation} = oldParams;
                const newQuery = {...(newProduct && {products: newProduct[0].name})};
                return {navigation: navigation, query: newQuery};
            });
        }
    }, []);

    const actionData = useMemo(
        () => ({
            params: usersParams,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [usersParams, getAccessTokenSilently, logout],
    );

    const productActionData = useMemo(
        () => ({
            params: {},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [getAccessTokenSilently, logout],
    );

    useEffect(() => {
        dispatch(fetchUsers(actionData));
        // dispatch(fetchProducts(productActionData));
    }, [actionData, dispatch, productActionData]);

    useEffect(() => {
        updateFilters(filters);
    }, [filters, updateFilters]);

    return (
        <div className={styles.manageTeamPage}>
            <ManageTeamList
                users={users}
                usersParams={usersParams}
                setUsersParams={setUsersParams}
                totalRecords={totalRecords}
                actionData={actionData}
                logout={logout}
                getAccessTokenSilently={getAccessTokenSilently}
            />
        </div>
    );
};

export default ManageTeamPage;
