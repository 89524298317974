export const slicesNames = {
    popUp: 'popUp',
    common: 'common',
    analyticsCustomers: 'analyticsCustomers',
    analyticsKpi: 'analyticsKpi',
    products: 'products',
    profileInfo: 'profileInfo',
    superUsers: 'superUsers',
    manageTeam: 'manageTeam',
    customers: 'customers',
};

export const statusesTypes = {
    loading: 'loading',
    finished: 'finished',
    error: 'error',
};
