import React, {FC} from 'react';
import {InputText} from 'primereact/inputtext';
import classNames from 'classnames';
import {inputTypes} from '../../../globalConstants';
import {FormikValues} from 'formik';

interface ICustomInput {
    id?: string;
    type: inputTypes;
    labelText?: string;
    warningText?: string;
    ariaDescribedby?: string;
    labelCols?: string;
    inputCols?: string;
    readOnly?: boolean;
    labelBoldText?: boolean;
    formik?: FormikValues;
}

const CustomInput: FC<ICustomInput> = ({
    id,
    type,
    labelText,
    warningText,
    labelCols,
    inputCols,
    readOnly = false,
    labelBoldText,
    formik,
}) => {
    return (
        <div className="p-field grid p-ai-start">
            <label
                htmlFor="firstName"
                className={classNames(labelBoldText && 'font-bold', labelCols ? labelCols : 'p-col-12 md:col-3')}>
                {labelText}
            </label>
            <div className={classNames(inputCols ? inputCols : 'p-col-12 md:col-9')}>
                <InputText
                    id={id}
                    type={type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[id]}
                    aria-describedby={`${id}-invalid`}
                    className={formik.touched[id] && formik.errors[id] ? 'p-invalid' : null}
                    readOnly={readOnly}
                />
                {formik.touched[id] && formik.errors[id] ? (
                    <small id={`${id}-invalid`} className="p-error p-d-block">
                        {formik.errors[id] || ''}
                    </small>
                ) : (
                    <small>{warningText}</small>
                )}
            </div>
        </div>
    );
};

export default CustomInput;
