import React from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import {withAuthenticationRequired} from '@auth0/auth0-react';

export interface IProtectedRouteProps {
    component: React.FC;
}

const ProtectedComponent: React.FC<IProtectedRouteProps> = ({component, ...propsForComponent}) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <ProgressSpinner />,
    });
    return <Component {...propsForComponent} />;
};

export default ProtectedComponent;
