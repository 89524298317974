import React from 'react';
import LeftBar from '../../LeftBar/LeftBar';
import ProductMenu from '../ProductMenu/ProductMenu';
import styles from './ProductHomePage.module.scss';
import {useLocation} from 'react-router-dom';

const ProductHomePage = () => {
    const {state} = useLocation();

    return (
        <div className={styles.productHomePageWrapper}>
            <LeftBar>
                <ProductMenu open={!!state} />
            </LeftBar>
            <div className={styles.productHomePage} />
        </div>
    );
};

export default ProductHomePage;
