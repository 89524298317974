import React, {useCallback, useEffect, useRef, FC, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import {customLogout, isAuth} from '../../auth/auth-service';
import {useAppDispatch} from '../../hooks/redux';
import {fetchProfileInfo, savePermissions} from '../../store/slices/profileInfo/thunks';
import avatarPlaceholder from '../../assets/img/avatar_placeholder.png';
import {selectProfileInfo, selectUserRole} from '../../store/slices/profileInfo/selectors';
import logo from '../../assets/img/logo-horiz.png';
import Toaster from './Toaster/Toaster';
import {userRoles} from '../../globalConstants';
import styles from './Navigation.module.scss';

interface IToken {
    'https://admin.starcom.com/app_metadata'?: object;
    iss?: string;
    sub?: string;
    aud?: [];
    iat?: number;
    exp?: number;
    azp?: string;
    scope?: string;
    permissions?: [];
}

const Navigation: FC = () => {
    const menu = useRef(null);
    const {loginWithRedirect, getAccessTokenSilently, logout} = useAuth0();
    const isAuthenticated = isAuth();
    const dispatch = useAppDispatch();

    const profileInfo = useSelector(selectProfileInfo);
    const userRole = useSelector(selectUserRole);

    const userName = useMemo(
        () =>
            profileInfo?.user?.firstName && profileInfo?.user?.lastName
                ? `${profileInfo.user.firstName} ${profileInfo.user.lastName}`
                : 'User Name',
        [profileInfo?.user?.firstName, profileInfo?.user?.lastName],
    );

    const menuItems = useMemo(
        () => [
            {
                label: 'Logout',
                icon: 'pi pi-power-off',
                command: () => {
                    customLogout(logout);
                },
            },
        ],
        [logout],
    );

    const actionData = useMemo(
        () => ({
            params: {},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [getAccessTokenSilently, logout],
    );

    const getToken = useCallback(async () => {
        if (isAuthenticated) {
            const token = await getAccessTokenSilently();
            localStorage.setItem('token', token);
            const decodedToken: IToken = token ? jwtDecode(token) : {};
            dispatch(savePermissions(decodedToken.permissions));
        }
        return isAuthenticated;
    }, [getAccessTokenSilently, isAuthenticated, dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            getToken();
        }
    }, [isAuthenticated, getToken]);

    useEffect(() => {
        isAuthenticated && dispatch(fetchProfileInfo(actionData));
    }, [actionData, dispatch, isAuthenticated]);

    return (
        <div className={styles.navigation}>
            <Toaster />
            {isAuth() ? (
                <>
                    <div className={styles.leftSideItems}>
                        <img src={logo} className={styles.logo} alt="" />

                        <span className={styles.slogan}>Support and Administration</span>
                    </div>
                    <div className={styles.centerSideItems}>
                        <NavLink to="/products" className={styles.navLink}>
                            Products
                        </NavLink>
                        {userRole === userRoles.SUPER_ADMIN && (
                            <NavLink to="/customers" className={styles.navLink}>
                                Customers
                            </NavLink>
                        )}
                        {userRole === userRoles.SUPER_ADMIN && (
                            <NavLink to="/manage-team" className={styles.navLink}>
                                Manage Team
                            </NavLink>
                        )}
                    </div>
                    <div className={styles.rightSideItems}>
                        <Menu model={menuItems} popup ref={menu} id="popup_menu" />
                        <li className={styles.profileItem}>
                            <button
                                type="button"
                                className="p-link"
                                onClick={(event: React.MouseEvent) => menu?.current?.toggle(event)}>
                                <img src={avatarPlaceholder} className={styles.profileImage} alt="" />
                                <span className={styles.profileName}>{userName}</span>
                            </button>
                        </li>
                    </div>
                </>
            ) : (
                <div className={styles.logAuthed}>
                    <div className={styles.leftSideItems}>
                        <img src={logo} className={styles.logo} alt="" />

                        <span className={styles.slogan}>Support and Administration</span>
                    </div>
                    <Button className={styles.auth} onClick={() => loginWithRedirect({})}>
                        Log In
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Navigation;
