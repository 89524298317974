import {sendRequest, urls} from '../../apiRequestService';
import {requestMethod, responseType} from '../../globalConstants';
import {ICustomersData} from './types';

export const getCustomers = (data: ICustomersData) => {
    return sendRequest(urls.CUSTOMER_COMPANIES, requestMethod.POST, responseType.JSON, data);
};

export const getCustomer = (data: ICustomersData) => {
    return sendRequest(`${urls.CUSTOMER_COMPANIES}/${data.id}`, requestMethod.GET, responseType.JSON, data);
};

export const getCustomerBillingDetails = (data: ICustomersData) => {
    return sendRequest(
        `${urls.CUSTOMER_COMPANIES}/${data.id}/${urls.SUBSCRIPTIONS}`,
        requestMethod.GET,
        responseType.JSON,
        data,
    );
};

export const getCustomerBillingHistory = (data: ICustomersData) => {
    return sendRequest(
        `${urls.CUSTOMER_COMPANIES}/${urls.BILLING_HISTORY}`,
        requestMethod.POST,
        responseType.JSON,
        data,
    );
};

export const getCustomerSubscriptions = (data: ICustomersData) => {
    return sendRequest(urls.SUBSCRIPTIONS, requestMethod.GET, responseType.JSON, data);
};

export const changeCustomerSubscription = (data: ICustomersData) => {
    return sendRequest(
        `${urls.CUSTOMER_COMPANIES}/${data.id}/${urls.SUBSCRIPTIONS}`,
        requestMethod.POST,
        responseType.JSON,
        data,
    );
};
