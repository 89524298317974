import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {DataTable, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import classNames from 'classnames';
import Footer from '../../../Common/Footer/Footer';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import SearchInput from '../../../Common/SearchInput/SearchInput';
import {selectLoading} from '../../../../store/slices/selectors';
import {slicesNames} from '../../../../store/slices/constants';
import {analyticsCustomersThunkNames} from '../../../../store/slices/analyticsCustomers/constants';
import {selectLastUpdateDate} from '../../../../store/slices/analyticsCustomers/selectors';
import {LINKS, requestMethod, responseType} from '../../../../globalConstants';
import {initialProductParams} from '../../constants';
import {IACustomersParams, IAnalyticCustomersProps} from '../../types';
import {IPagination, ITableSort} from '../../../../globalTypes';
import styles from './AnalyticsCustomersList.module.scss';
import {updateAnalyticsCustomers} from '../../../../store/slices/analyticsCustomers/thunks';
import {useAppDispatch} from '../../../../hooks/redux';

const AnalyticCustomersList: FC<IAnalyticCustomersProps> = ({
    customers,
    customersParams,
    setCustomersParams,
    exportCustomers,
    totalRecords,
    logout,
    getAccessTokenSilently,
    fetchCustomers,
}) => {
    const [sort, setSort] = useState<ITableSort>({field: 'companyName', order: 1});
    const [pagination, setPagination] = useState({first: 0, rows: 20});

    const isLoading = useSelector(
        selectLoading(slicesNames.analyticsCustomers, analyticsCustomersThunkNames.updateAnalyticsCustomers),
    );

    const isUpdateLoading = useSelector(
        selectLoading(slicesNames.analyticsCustomers, analyticsCustomersThunkNames.fetchAnalyticsCustomers),
    );

    const lastUpdateDate = useSelector(selectLastUpdateDate);
    const {productName} = useParams();
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const dispatch = useAppDispatch();

    const breadCrumbItems = useMemo(
        () => [
            {
                label: 'Products',
                command: () => {
                    navigate(LINKS.PRODUCTS);
                },
            },
            {
                label: productName,
                command: () => {
                    navigate(`${LINKS.PRODUCTS}/${productName}`);
                },
            },
            {
                label: 'Analytics',
                command: () => {
                    navigate(`${LINKS.PRODUCTS}/${productName}`, {state: true});
                },
            },
            {label: 'Customers'},
        ],
        [navigate, productName],
    );

    const onSort = useCallback(
        ({sortField, sortOrder}: {sortField: string; sortOrder: DataTableSortOrderType}) => {
            setSort({field: sortField, order: sortOrder});
            setCustomersParams((oldParams: IACustomersParams) => {
                const {query} = oldParams;
                return {
                    query: query,
                    navigation: {
                        page: {from: 0, size: 20},
                        sort: [{key: sortField, order: sortOrder === 1 ? 'ASC' : 'DESC'}],
                    },
                };
            });
        },
        [setCustomersParams],
    );

    const onPageSelect = useCallback(
        (pagination: IPagination) => {
            setPagination({first: pagination.first, rows: pagination.rows});
            setCustomersParams(() => {
                return {
                    query: {},
                    navigation: {
                        sort: [
                            {
                                key: 'companyName',
                                order: 'ASC',
                            },
                        ],
                        page: {from: pagination.first, size: pagination.rows},
                    },
                };
            });
        },
        [setCustomersParams],
    );

    const updateCustomers = useCallback(async () => {
        const actionData = {
            method: requestMethod.POST,
            responseType: responseType.JSON,
            params: {},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };
        dispatch(updateAnalyticsCustomers(actionData)).then(fetchCustomers);
    }, [fetchCustomers, getAccessTokenSilently, logout, dispatch]);

    const header = useMemo(
        () => (
            <div className={styles.tableHeader}>
                <div className={styles.updateCustomers}>
                    <Button onClick={updateCustomers}>Update Customers</Button>
                    <div className={styles.lastUpdated}>Customers was updated: {lastUpdateDate}</div>
                </div>
                <div className={styles.searchBlock}>
                    <SearchInput setParams={setCustomersParams} />
                    <Button
                        label="Export"
                        icon="pi pi-upload"
                        className={`p-button-help p-button-raised ${styles.exportButton}`}
                        onClick={exportCustomers}
                    />
                </div>
            </div>
        ),
        [exportCustomers, lastUpdateDate, setCustomersParams, updateCustomers],
    );

    return (
        <div className={classNames(styles.analyticCustomers, 'list-generic')}>
            <BreadCrumb items={breadCrumbItems} />
            <DataTable
                ref={tableRef}
                className="table-generic table-with-header p-datatable-sm"
                value={customers}
                header={header}
                resizableColumns
                reorderableColumns
                columnResizeMode="expand"
                selectionMode="single"
                cellSelection
                dataKey="id"
                sortField={sort.field}
                sortOrder={sort.order}
                onSort={onSort}
                scrollable={true}
                emptyMessage=""
                loading={isLoading || isUpdateLoading}
                frozenWidth="220px">
                <Column
                    className="p-text-nowrap p-text-truncate"
                    field="companyName"
                    columnKey="companyName"
                    reorderable
                    header="Name"
                    headerStyle={{width: '220px', height: '48px'}}
                    bodyStyle={{height: '50px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    className="p-text-nowrap p-text-truncate"
                    field="subscriptionName"
                    columnKey="subscriptionName"
                    reorderable
                    header="Subscription Tier"
                    headerStyle={{width: '150px', height: '48px'}}
                    bodyStyle={{height: '50px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="subscriptionRenewalDate"
                    columnKey="subscriptionRenewalDate"
                    reorderable
                    header="Renewal Date"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="employeeCount"
                    columnKey="employeeCount"
                    reorderable
                    header="User Count"
                    headerStyle={{width: '150px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    className="p-text-nowrap p-text-truncate"
                    field="companyStartDate"
                    columnKey="companyStartDate"
                    reorderable
                    header="Start Date"
                    headerStyle={{width: '220px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    className="p-text-nowrap p-text-truncate"
                    field="lastLoginDate"
                    columnKey="lastLoginDate"
                    reorderable
                    header="Last Login"
                    headerStyle={{width: '220px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="trialWeekCount"
                    columnKey="trialWeekCount"
                    reorderable
                    header="Trial Week Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="trialMonthCount"
                    columnKey="trialMonthCount"
                    reorderable
                    header="Trial Month Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="trialQuarterCount"
                    columnKey="trialQuarterCount"
                    reorderable
                    header="Trial Quarter Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="trialYearCount"
                    columnKey="trialYearCount"
                    reorderable
                    header="Trial Year Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="totalTrialCount"
                    columnKey="totalTrialCount"
                    reorderable
                    header="Trial Total Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="plantWeekCount"
                    columnKey="plantWeekCount"
                    reorderable
                    header="Plant Week Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="plantMonthCount"
                    columnKey="plantMonthCount"
                    reorderable
                    header="Plant Month Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="plantQuarterCount"
                    columnKey="plantQuarterCount"
                    reorderable
                    header="Plant Quarter Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="plantYearCount"
                    columnKey="plantYearCount"
                    reorderable
                    header="Plant Year Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="totalPlantCount"
                    columnKey="totalPlantCount"
                    reorderable
                    header="Plant Total Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '150px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="trialLogWeekCount"
                    columnKey="trialLogWeekCount"
                    reorderable
                    header="Week Log Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '170px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="trialLogMonthCount"
                    columnKey="trialLogMonthCount"
                    reorderable
                    header="Month Log Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '170px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="trialLogQuarterCount"
                    columnKey="trialLogQuarterCount"
                    reorderable
                    header="Quarter Log Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '170px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="trialLogYearCount"
                    columnKey="trialLogYearCount"
                    reorderable
                    header="Year Log Count"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '170px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
                <Column
                    field="trialLogWeekAvg"
                    columnKey="trialLogWeekAvg"
                    reorderable
                    header="AvgWeeklyLogs"
                    className="p-text-nowrap p-text-truncate"
                    headerStyle={{width: '170px', height: '48px'}}
                    sortable
                    sortFunction={() => customers}
                />
            </DataTable>
            <Footer
                totalRecords={totalRecords}
                initParams={initialProductParams}
                params={customersParams}
                pagination={pagination}
                type="customer"
                onPageSelect={onPageSelect}
            />
        </div>
    );
};

export default AnalyticCustomersList;
