import {fetchAnalyticsCustomers} from './thunks';
import createGenericSlice from '../createGenericSlice';
import {slicesNames} from '../constants';

export const analyticsCustomersSlice = createGenericSlice({
    name: slicesNames.analyticsCustomers,
    initialState: {
        data: {
            lastUpdateDate: '',
            result: [],
            page: {
                from: 0,
                size: 20,
                total: 0,
            },
        },
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAnalyticsCustomers.fulfilled, (state, {payload}) => {
            if (payload) state.data = payload;
        });
    },
});

export const {resetSlice} = analyticsCustomersSlice.actions;
