import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import {setupStore} from './store/store';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import './index.css';

const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <Router>
            <Auth0ProviderWithHistory>
                <App />
            </Auth0ProviderWithHistory>
        </Router>
    </Provider>,
);

reportWebVitals();
