import {
    fetchCustomer,
    fetchCustomerBillingDetails,
    fetchCustomerBillingHistory,
    fetchCustomers,
    fetchCustomerSubscriptions,
} from './thunks';
import createGenericSlice from '../createGenericSlice';
import {slicesNames} from '../constants';

export const customerSlice = createGenericSlice({
    name: slicesNames.customers,
    initialState: {
        data: {
            customers: {
                result: [],
                page: {
                    from: 0,
                    size: 20,
                    total: 0,
                },
            },
            selectedCustomer: {
                id: '',
                name: '',
                webAddress: '',
                phoneNumber: '',
                address: {
                    address: '',
                    address2: '',
                    city: '',
                    countryId: '',
                    stateId: '',
                    postalCode: '',
                },
            },
            billingDetails: {
                subscription: {
                    productType: '',
                    billingPeriod: '',
                },
                card: {
                    cardholderName: '',
                    hiddenCardNumber: '',
                    expirationDate: '',
                },
                customer: {
                    name: '',
                    email: '',
                },
            },
            billingHistory: {
                result: [],
                page: {
                    from: 0,
                    size: 20,
                    total: 0,
                },
            },
            subscriptions: [],
        },
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCustomers.fulfilled, (state, {payload}) => {
            if (payload) state.data.customers = payload;
        });
        builder.addCase(fetchCustomer.fulfilled, (state, {payload}) => {
            if (payload) state.data.selectedCustomer = payload;
        });
        builder.addCase(fetchCustomerBillingDetails.fulfilled, (state, {payload}) => {
            if (payload) state.data.billingDetails = payload.subscriptions[0];
        });
        builder.addCase(fetchCustomerBillingHistory.fulfilled, (state, {payload}) => {
            if (payload) state.data.billingHistory = payload;
        });
        builder.addCase(fetchCustomerSubscriptions.fulfilled, (state, {payload}) => {
            if (payload) state.data.subscriptions = payload.subscriptions;
        });
    },
});

export const {resetSlice} = customerSlice.actions;
