export default {
    ANALYTIC_COMPANY: 'ppt/analytic/company',
    ANALYTIC_COMPANY_UPDATE: 'ppt/analytic/company/update',
    ANALYTIC_COMPANY_EXCEL: 'ppt/analytic/company/excel',
    ANALYTIC_FINANCE: 'ppt/analytic/finance',
    PRODUCTS: 'products',
    CUSTOMERS: 'customers',
    PROFILE_INFO: 'users/profile',
    USERS: 'users',
    USERS_SEARCH: 'users/search',
    ROLES: 'roles',
    SUPER_USERS: 'ppt/super-users',
    SUPER_USERS_COMPANIES: 'ppt/super-users/companies',
    CUSTOMER_COMPANIES: 'companies',
    SUBSCRIPTIONS: 'subscriptions',
    BILLING_HISTORY: 'billing-history',
};
