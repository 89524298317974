import {sendRequest, urls} from '../../apiRequestService';
import {requestMethod, responseType} from '../../globalConstants';
import {IAnalyticsData} from './types';

export const getAnalyticsCustomers = (data: IAnalyticsData) => {
    return sendRequest(urls.ANALYTIC_COMPANY, requestMethod.POST, responseType.JSON, data);
};

export const updateCustomers = (data: IAnalyticsData) => {
    return sendRequest(urls.ANALYTIC_COMPANY_UPDATE, requestMethod.POST, responseType.JSON, data);
};
