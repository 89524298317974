import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAuth0} from '@auth0/auth0-react';
import {isEmpty} from 'lodash';
import {fetchSuperUsers} from '../../../store/slices/superUsers/thunks';
import {useAppDispatch} from '../../../hooks/redux';
import SuperUsersList from '../SuperUsersList/SuperUsersList';
import {selectCompanies, selectTotalRecords} from '../../../store/slices/superUsers/selectors';
import {initialSuperUserParams} from '../constants';
import {ICompany} from '../../../api/superUsers/types';
import styles from './SuperUsersPage.module.scss';

const SuperUsersPage: FC = () => {
    const [companies, setCompanies] = useState([]);
    const [companiesParams, setCompaniesParams] = useState(initialSuperUserParams);

    const selectedCompanies = useSelector(selectCompanies);
    const totalRecords = useSelector(selectTotalRecords);

    const dispatch = useAppDispatch();
    const {logout, getAccessTokenSilently} = useAuth0();

    const actionData = useMemo(
        () => ({
            params: companiesParams,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [companiesParams, getAccessTokenSilently, logout],
    );

    const mappedCompanies = useCallback((data: ICompany[]) => {
        return data?.map((item: ICompany) => {
            return {
                company: {
                    id: item.company.id,
                    companyName: item.company.name,
                },
                createdSuperUser: item.createdSuperUser,
                groupLabel: item.createdSuperUser ? 'Yes' : 'No',
            };
        });
    }, []);

    useEffect(() => {
        if (!isEmpty(selectedCompanies)) {
            setCompanies(mappedCompanies(selectedCompanies));
        }
    }, [mappedCompanies, selectedCompanies]);

    useEffect(() => {
        dispatch(fetchSuperUsers(actionData));
    }, [actionData, dispatch]);

    return (
        <div className={styles.superUsersPage}>
            <SuperUsersList
                companies={companies}
                companiesParams={companiesParams}
                totalRecords={totalRecords}
                setCompaniesParams={setCompaniesParams}
                logout={logout}
                getAccessTokenSilently={getAccessTokenSilently}
                actionData={actionData}
            />
        </div>
    );
};

export default SuperUsersPage;
