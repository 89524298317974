import React, {FC} from 'react';
import {InputText} from 'primereact/inputtext';
import {debounce} from 'lodash';
import {IManageTeamParams} from '../../ManageTeam/types';

interface ISearchInput {
    setParams?: (arg: any) => void;
}

const SearchInput: FC<ISearchInput> = ({setParams}) => {
    const onGlobalSearch = debounce((event) => {
        setParams((oldParams: IManageTeamParams) => {
            const {navigation} = oldParams;
            return {
                navigation: navigation,
                query: event.target.value ? {search: event.target.value} : {},
            };
        });
    }, 500);

    return (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={onGlobalSearch} placeholder="Search..." />
        </span>
    );
};

export default SearchInput;
