import React, {FC} from 'react';
import styles from './DropdownFilter.module.scss';
import {Dropdown} from 'primereact/dropdown';

interface IItem {
    id: number;
    name: string;
}

interface IProps {
    label: string;
    value: IItem;
    options: IItem[];
    optionLabel: string;
    placeholder: string;
    onChange: (arg: any) => void;
    showClear: boolean;
}

const DropdownFilter: FC<IProps> = ({label, value, options, optionLabel, placeholder, onChange, showClear}) => {
    return (
        <div className={styles.dropdownFilter}>
            <div className={styles.labelText}>{label}</div>
            <Dropdown
                onChange={onChange}
                optionLabel={optionLabel}
                value={value}
                className={`p-dropdown ${styles.dropdownInput}`}
                options={options}
                placeholder={placeholder}
                showClear={showClear}
            />
        </div>
    );
};

export default DropdownFilter;
