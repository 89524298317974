import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {BreadCrumb as PrimeBreadCrumb} from 'primereact/breadcrumb';
import styles from './BreadCrumb.module.scss';

interface IItem {
    label: string;
}

interface IProps {
    items: IItem[];
}

const BreadCrumb: FC<IProps> = ({items}) => {
    const navigate = useNavigate();

    const home = {
        icon: 'pi pi-home',
        command: () => {
            navigate('/');
        },
    };

    return (
        <div className={styles.breadCrumb}>
            <PrimeBreadCrumb model={items} home={home} />
        </div>
    );
};

export default BreadCrumb;
