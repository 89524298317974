import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import classNames from 'classnames';
import {DataTable, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {deleteSuperUser, createSuperUser, fetchSuperUsers} from '../../../store/slices/superUsers/thunks';
import {useAppDispatch} from '../../../hooks/redux';
import {selectLoading} from '../../../store/slices/selectors';
import {slicesNames} from '../../../store/slices/constants';
import {superUsersThunkNames} from '../../../store/slices/superUsers/constants';
import {selectProfileInfo} from '../../../store/slices/profileInfo/selectors';
import Footer from '../../Common/Footer/Footer';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import CustomDialog from '../../Common/CustomDialog/CustomDialog';
import SearchInput from '../../Common/SearchInput/SearchInput';
import {IPagination, ITableSort} from '../../../globalTypes';
import {IMappedCompany, ISuperUsersParams, ISuperUsersProps} from '../types';
import {IGetSuperUsersCompData} from '../../../api/superUsers/types';
import {LINKS} from '../../../globalConstants';
import styles from './SuperUsersList.module.scss';

const SuperUsersList: FC<ISuperUsersProps> = ({
    companies,
    setCompaniesParams,
    companiesParams,
    totalRecords,
    logout,
    getAccessTokenSilently,
    actionData,
}) => {
    const [sort, setSort] = useState<ITableSort>({field: 'companyName', order: 1});
    const [pagination, setPagination] = useState({first: 0, rows: 20});
    const [superUserToDelete, setSuperUserToDelete] = useState(null);
    const [superUserToCreate, setSuperUserToCreate] = useState(null);

    const tableRef = useRef(null);
    const dispatch = useAppDispatch();
    const {productName} = useParams();
    const navigate = useNavigate();

    const profileInfo = useSelector(selectProfileInfo);
    const isLoading = useSelector(selectLoading(slicesNames.superUsers, superUsersThunkNames.fetchSuperUsers));

    const breadCrumbItems = useMemo(
        () => [
            {
                label: 'Products',
                command: () => {
                    navigate(LINKS.PRODUCTS);
                },
            },
            {
                label: productName,
                command: () => {
                    navigate(`${LINKS.PRODUCTS}/${productName}`);
                },
            },
            {label: 'Super Users'},
        ],
        [navigate, productName],
    );

    const onPageSelect = useCallback(
        (pagination: IPagination) => {
            setPagination({first: pagination.first, rows: pagination.rows});
            setCompaniesParams(() => {
                return {
                    query: {},
                    navigation: {
                        sort: [
                            {
                                key: 'companyName',
                                order: 'ASC',
                            },
                        ],
                        page: {from: pagination.first, size: pagination.rows},
                    },
                };
            });
        },
        [setCompaniesParams],
    );

    const hasCreatedUser = useCallback(() => {
        return companies.some((company: IMappedCompany) => company.createdSuperUser);
    }, [companies]);

    const confirmDeleteUser = useCallback((company: IMappedCompany) => {
        setSuperUserToDelete(company);
    }, []);

    const confirmCreateUser = useCallback((company: IMappedCompany) => {
        setSuperUserToCreate(company);
    }, []);

    const createUserBodyTemplate = useCallback((rowData: IMappedCompany) => {
        return <>{rowData.createdSuperUser ? 'Yes' : 'No'}</>;
    }, []);

    const deleteUserBodyTemplate = useCallback(
        (rowData: IMappedCompany) => {
            return (
                <>
                    {rowData.createdSuperUser ? (
                        <Button
                            label={'Delete Super User'}
                            className=" p-button-text"
                            onClick={() => confirmDeleteUser(rowData)}
                        />
                    ) : (
                        <Button
                            label={'Create Super User'}
                            className=" p-button-text"
                            onClick={() => confirmCreateUser(rowData)}
                            disabled={hasCreatedUser() && !rowData.createdSuperUser}
                        />
                    )}
                </>
            );
        },
        [confirmCreateUser, confirmDeleteUser, hasCreatedUser],
    );

    const hideDeleteSuperUserDialog = useCallback(() => {
        setSuperUserToDelete(null);
    }, []);

    const hideCreateSuperUserDialog = useCallback(() => {
        setSuperUserToCreate(null);
    }, []);

    const actionDeleteData = useMemo(
        () => ({
            params: {},
            id: profileInfo.user.id,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [getAccessTokenSilently, logout, profileInfo.user.id],
    );

    const actionCreateData = useMemo(
        () => ({
            params: {companyId: superUserToCreate?.company?.id},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [getAccessTokenSilently, logout, superUserToCreate?.company?.id],
    );

    const deleteUser = useCallback(() => {
        dispatch(deleteSuperUser(actionDeleteData)).then(() => {
            setSuperUserToDelete(null);
            dispatch(fetchSuperUsers(actionData as IGetSuperUsersCompData));
        });
    }, [actionData, actionDeleteData, dispatch, setSuperUserToDelete]);

    const createUser = useCallback(() => {
        dispatch(createSuperUser(actionCreateData)).then(() => {
            setSuperUserToCreate(null);
            dispatch(fetchSuperUsers(actionData as IGetSuperUsersCompData));
        });
    }, [actionCreateData, actionData, dispatch]);

    const onSort = useCallback(
        ({sortField, sortOrder}: {sortField: string; sortOrder: DataTableSortOrderType}) => {
            const afterDot = sortField.substring(sortField.indexOf('.') + 1);

            setSort({field: sortField, order: sortOrder});
            setCompaniesParams((oldParams: ISuperUsersParams) => {
                const {query} = oldParams;
                return {
                    query: query,
                    navigation: {
                        page: {from: 0, size: 20},
                        sort: [{key: afterDot, order: sortOrder === 1 ? 'ASC' : 'DESC'}],
                    },
                };
            });
        },
        [setCompaniesParams],
    );

    const header = useMemo(
        () => (
            <div className={styles.tableHeader}>
                <SearchInput setParams={setCompaniesParams} />
            </div>
        ),
        [setCompaniesParams],
    );

    return (
        <div className={classNames(styles.superUsersList, 'list-generic')}>
            <BreadCrumb items={breadCrumbItems} />
            <DataTable
                ref={tableRef}
                className="table-generic table-with-header p-datatable-sm"
                reorderableColumns
                value={companies}
                header={header}
                resizableColumns
                columnResizeMode="expand"
                selectionMode="single"
                cellSelection
                dataKey="id"
                sortField={sort.field}
                sortOrder={sort.order}
                onSort={onSort}
                scrollable={true}
                emptyMessage=""
                loading={isLoading}>
                <Column
                    className="p-text-nowrap p-text-truncate"
                    field="company.companyName"
                    columnKey="company.companyName"
                    header="Company"
                    headerStyle={{width: '270px', height: '48px'}}
                    bodyStyle={{height: '50px'}}
                    sortable
                    sortFunction={() => companies}
                />
                <Column
                    field="createdSuperUser"
                    columnKey="createdSuperUser"
                    header="Created Super User"
                    headerStyle={{height: '48px', width: '150px'}}
                    body={createUserBodyTemplate}
                    sortable
                    sortFunction={() => companies}
                />
                <Column
                    field="createdSuperUser"
                    columnKey="createdSuperUser"
                    header="Create/Delete Super User"
                    headerStyle={{height: '48px', width: '150px'}}
                    body={deleteUserBodyTemplate}
                />
            </DataTable>
            <Footer
                totalRecords={totalRecords}
                initParams={{}}
                params={companiesParams}
                pagination={pagination}
                type="company"
                onPageSelect={onPageSelect}
            />
            <CustomDialog
                visible={superUserToDelete}
                header="Delete Confirmation"
                footerConfirmHandler={deleteUser}
                onHideHandler={hideDeleteSuperUserDialog}
                message="Are you sure you want to delete super user for company"
                name={superUserToDelete?.company?.companyName}
            />
            <CustomDialog
                visible={superUserToCreate}
                header="Create Confirmation"
                footerConfirmHandler={createUser}
                onHideHandler={hideCreateSuperUserDialog}
                message="Are you sure you want to create super user for company"
                name={superUserToCreate?.company?.companyName}
            />
        </div>
    );
};

export default SuperUsersList;
