import {IUser} from '../../api/manageTeam/types';

export const getMappedUsers = (users: IUser[]) => {
    return users.map((user: IUser) => {
        return {
            id: user.id,
            userEmail: user.email,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            roles: user.roles,
        };
    });
};
