import {ICompany} from '../../api/customers/types';
import {getStateNameByID, getCountryNameByID} from '../Common/utils';

export const getMappedCustomers = (customers: ICompany[]) => {
    return customers.map((customer: ICompany) => ({
        id: customer.id,
        name: customer.name,
        createdDate: customer.createdDate,
        lastSignIn: customer.lastSignIn,
        phoneNumber: customer.phoneNumber,
        signIn: customer.signIn,
        products: customer.products,
        address: {
            stateId: getStateNameByID(customer.address.countryId, customer.address.stateId),
            countryId: getCountryNameByID(customer.address.countryId),
        },
    }));
};
