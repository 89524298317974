import {createAsyncThunk} from '@reduxjs/toolkit';
import {IActionData} from '../../../apiRequestService/types';
import {slicesNames} from '../constants';
import {superUsersThunkNames} from './constants';
import {setPopUp} from '../popUp/popUpSlice';
import {defaultErrorMessage, popUpInfo} from '../../../globalConstants';
import {getSuperUsers, deleteUser, createUser} from '../../../api/superUsers';
import {IGetSuperUsersCompData} from '../../../api/superUsers/types';

export const fetchSuperUsers = createAsyncThunk(
    `${slicesNames.superUsers}/${superUsersThunkNames.fetchSuperUsers}`,
    async (data: IGetSuperUsersCompData, {dispatch}) => {
        try {
            const response = await getSuperUsers(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const deleteSuperUser = createAsyncThunk(
    `${slicesNames.superUsers}/${superUsersThunkNames.deleteUser}`,
    async (data: IActionData, {dispatch}) => {
        try {
            const response = await deleteUser(data);
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.SUCCESS,
                        summary: popUpInfo.summary.SUCCESSFUL,
                        detail: 'User was deleted',
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
            return response.data;
        } catch (err) {
            const {message} = err.response.data.message || '';
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const createSuperUser = createAsyncThunk(
    `${slicesNames.superUsers}/${superUsersThunkNames.createUser}`,
    async (data: IActionData, {dispatch}) => {
        try {
            const response = await createUser(data);
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.SUCCESS,
                        summary: popUpInfo.summary.SUCCESSFUL,
                        detail: 'User was created',
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
            return response.data;
        } catch (err) {
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: err.response.data.message || defaultErrorMessage,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);
