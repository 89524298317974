import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {productThunkNames} from './constants';
import {getProducts} from '../../../api/products';
import {setPopUp} from '../popUp/popUpSlice';
import {defaultErrorMessage, popUpInfo} from '../../../globalConstants';
import {IProductsData} from '../../../api/products/types';

export const fetchProducts = createAsyncThunk(
    `${slicesNames.products}/${productThunkNames.fetchProducts}`,
    async (data: IProductsData, {dispatch}) => {
        try {
            const response = await getProducts(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);
