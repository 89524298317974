import React, {useCallback, useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import LeftBar from '../../../LeftBar/LeftBar';
import CustomerMenu from '../../CustomerMenu/CustomerMenu';
import BillingHistoryList from '../BillingHistoryList/BillingHistoryList';
import {fetchCustomerBillingHistory} from '../../../../store/slices/customers/thunks';
import {useAppDispatch} from '../../../../hooks/redux';
import {getInitialBillingHistoryParams} from '../../constants';
import {useParams} from 'react-router-dom';

const BillingHistoryPage = () => {
    const {id} = useParams();
    const [params, setParams] = useState(getInitialBillingHistoryParams(+id));

    const dispatch = useAppDispatch();
    const {logout, getAccessTokenSilently} = useAuth0();

    const fetchBillingHistory = useCallback(() => {
        const actionData = {
            params: params,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };

        dispatch(fetchCustomerBillingHistory(actionData));
    }, [params, logout, getAccessTokenSilently, dispatch]);

    useEffect(() => {
        fetchBillingHistory();
    }, [fetchBillingHistory]);

    return (
        <>
            <LeftBar>
                <CustomerMenu />
            </LeftBar>
            <BillingHistoryList params={params} setParams={setParams} />
        </>
    );
};

export default BillingHistoryPage;
