import {createAsyncThunk} from '@reduxjs/toolkit';
import {slicesNames} from '../constants';
import {manageTeamThunkNames} from './constants';
import {setPopUp} from '../popUp/popUpSlice';
import {defaultErrorMessage, popUpInfo} from '../../../globalConstants';
import {getUsers, deleteUser, createUser, getRoles, getUser, getProducts, updateUser} from '../../../api/manageTeam';
import {
    ICreateUserData,
    IDeleteUserData,
    IGetProductsData,
    IGetRolesData,
    IGetUserData,
    IGetUsersData,
} from '../../../api/manageTeam/types';

export const fetchUsers = createAsyncThunk(
    `${slicesNames.manageTeam}/${manageTeamThunkNames.fetchUsers}`,
    async (data: IGetUsersData, {dispatch}) => {
        try {
            const response = await getUsers(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const fetchUser = createAsyncThunk(
    `${slicesNames.manageTeam}/${manageTeamThunkNames.fetchUser}`,
    async (data: IGetUserData, {dispatch}) => {
        try {
            const response = await getUser(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const removeUser = createAsyncThunk(
    `${slicesNames.manageTeam}/${manageTeamThunkNames.deleteUser}`,
    async (data: IDeleteUserData, {dispatch}) => {
        try {
            const response = await deleteUser(data);
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.SUCCESS,
                        summary: popUpInfo.summary.SUCCESSFUL,
                        detail: 'User was deleted',
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const addUser = createAsyncThunk(
    `${slicesNames.manageTeam}/${manageTeamThunkNames.createUser}`,
    async (data: ICreateUserData, {dispatch}) => {
        try {
            const response = await createUser(data);
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.SUCCESS,
                        summary: popUpInfo.summary.SUCCESSFUL,
                        detail: 'User was created',
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const editUser = createAsyncThunk(
    `${slicesNames.manageTeam}/${manageTeamThunkNames.updateUser}`,
    async (data: ICreateUserData, {dispatch}) => {
        try {
            const response = await updateUser(data);
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.SUCCESS,
                        summary: popUpInfo.summary.SUCCESSFUL,
                        detail: 'User was updated',
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const fetchRoles = createAsyncThunk(
    `${slicesNames.manageTeam}/${manageTeamThunkNames.getRoles}`,
    async (data: IGetRolesData, {dispatch}) => {
        try {
            const response = await getRoles(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);

export const fetchProducts = createAsyncThunk(
    `${slicesNames.manageTeam}/${manageTeamThunkNames.getProducts}`,
    async (data: IGetProductsData, {dispatch}) => {
        try {
            const response = await getProducts(data);
            return response.data;
        } catch (err) {
            const {message} = err.response.data || defaultErrorMessage;
            dispatch(
                setPopUp({
                    data: {
                        severity: popUpInfo.severities.ERROR,
                        summary: popUpInfo.summary.ERROR,
                        detail: message,
                        life: 3000,
                        sticky: '',
                    },
                }),
            );
        }
    },
);
