export const popUpInfo = {
    severities: {
        SUCCESS: 'success',
        INFO: 'info',
        WARN: 'warn',
        ERROR: 'error',
    },
    summary: {
        SUCCESSFUL: 'Successful',
        ERROR: 'Error',
    },
};

export const LINKS = {
    HOME: '/',
    ANALYTIC_CUSTOMER: '/analytic-customer',
    ANALYTIC_KPI: '/analytic-kpi',
    ANALYTIC_CUSTOMERS: '/analytics-customers',
    BILLING_DETAILS: '/billing-details',
    BILLING_HISTORY: '/billing-history',
    CUSTOMERS: '/customers',
    CUSTOMER: '/customers/:id',
    CUSTOMER_BILLING_DETAILS: '/customers/:id/billing-details',
    CUSTOMER_BILLING_HISTORY: '/customers/:id/billing-history',
    CUSTOMER_SUBSCRIPTIONS: '/customers/:id/subscriptions',
    MANAGE_TEAM: '/manage-team',
    PRODUCTS: '/products',
    PRODUCT: '/products/:productName',
    PRODUCT_SYSTEM: '/products/:productName/system',
    PRODUCT_KPI: '/products/:productName/analytic-kpi',
    PRODUCT_CUSTOMERS: '/products/:productName/analytics-customers',
    SYSTEM: '/system',
    SUPER_USERS: '/super-users',
    SUPER_USER: '/products/:productName/super-users',
    SUBSCRIPTIONS: '/subscriptions',
};

export const dateFormat = {
    EU: 'DD/MM/yyyy',
    US: 'MM/DD/yyyy',
    ALBANIA: 'yyyy/MM/DD',
};

export const timeFormat = {
    EU: 'HH:mm',
    US: 'h:mm A',
};

export const dateWithTimeFormat = {
    EU: 'DD/MM/yyyy HH:mm',
    US: 'MM/DD/yyyy h:mm A',
    ALBANIA: 'yyyy/MM/DD HH:mm',
};

export const dateWithFullTimeFormat = {
    EU: 'DD/MM/yyyy HH:mm:ss',
    US: 'MM/DD/yyyy h:mm:ss A',
    ALBANIA: 'yyyy/MM/DD HH:mm:ss',
};

export const dateFormatForSave = 'yyy-MM-DD';

export const requestMethod = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

export const PLANT_PARTNER_ADMIN = 'Plant Partner Admin';

export const defaultErrorMessage = 'Something went wrong!';

export const userPermissions = {
    SUPER_ADMIN_WRITE: 'starcom:write',
    SUPER_ADMIN_READ: 'starcom:read',
    PPT_ADMIN_WRITE: 'ppt:write:admin',
    PPT_ADMIN_READ: 'ppt:read:admin',
    PPT_ANALYTIC: 'ppt:read:analytic',
};

export const userRoles = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    PPT_ADMIN: 'PPT_ADMIN',
    PPT_ANALYTIC: 'PPT_ANALYTIC',
};

export enum responseType {
    JSON = 'json',
    BLOB = 'blob',
}

export enum inputTypes {
    text = 'text',
    search = 'search',
}
