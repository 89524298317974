import {IInitialInfo} from './types';

export const defaultSort = [
    {
        key: 'userFirstName',
        order: 'ASC',
    },
];

export const defaultPage = {
    from: 0,
    size: 20,
};

export const defaultNavigation = {
    sort: defaultSort,
    page: defaultPage,
};

export const initialUsersParams = {
    query: {},
    navigation: defaultNavigation,
};

export const initialManageTeamInfo: IInitialInfo = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
};
