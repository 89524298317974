import React, {FC} from 'react';
import {Link} from 'react-router-dom';

interface IProps {
    errorCode: string;
    errorText: string;
    linkTo: string;
    linkText: string;
}

const GenericErrorPage: FC<IProps> = ({errorCode, errorText, linkText, linkTo}) => {
    return (
        <div style={{textAlign: 'center'}}>
            <h1 style={{fontSize: 'xxx-large'}}>{errorCode}</h1>
            <h2>{errorText}</h2>
            <Link to={linkTo}>{linkText} </Link>
        </div>
    );
};

export default GenericErrorPage;
