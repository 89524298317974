import React, {FC, useCallback, useState} from 'react';
import {DropdownChangeParams} from 'primereact/dropdown';
import MenuDivider from '../../../Common/Menu/MenuDivider';
import FilterControls from '../../../Common/Filters/FilterControls';
import {getFilteredDate} from '../../utils';
import CalendarFilter from '../../../Common/Filters/CalendarFilter';
import MultiSelectFilter from '../../../Common/Filters/MultiSelectFilter';
import DropdownFilter from '../../../Common/Filters/DropdownFilter';
import {ICustomersSubscription, IFromTo} from '../../types';
import {dateFormat} from '../../../../globalConstants';

interface IOption {
    id: number;
    name: string;
}
interface IProps {
    dateOptions: IOption[];
    subscriptionOptions: IOption[];
    applyNewFilters: (arg1: IFromTo | null, arg2: Date[] | null, arg3: ICustomersSubscription[]) => void;
}

const AnalyticCustomerFilters: FC<IProps> = ({dateOptions, subscriptionOptions, applyNewFilters}) => {
    const [selectedRenewal, setSelectedRenewal] = useState(null);
    const [selectedInactive, setSelectedInactive] = useState(null);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState(null);

    const [selectedRenewalDate, setSelectedRenewalDate] = useState(null);

    const onResetFilters = useCallback(() => {
        setSelectedRenewal(null);
        setSelectedInactive(null);
        setSelectedSubscriptions(null);
        setSelectedRenewalDate(null);
        applyNewFilters(null, null, null);
    }, [applyNewFilters]);

    const onApplyFilters = useCallback(() => {
        applyNewFilters(selectedRenewalDate, selectedInactive, selectedSubscriptions);
    }, [applyNewFilters, selectedInactive, selectedRenewalDate, selectedSubscriptions]);

    const onRenewalChange = useCallback((event: DropdownChangeParams) => {
        setSelectedRenewal(event.value);
        setSelectedRenewalDate(getFilteredDate(event.value));
    }, []);

    return (
        <div className="side-filters">
            <FilterControls resetFilters={onResetFilters} applyFilters={onApplyFilters} />
            <MenuDivider />
            <div className="filtersInputs">
                <DropdownFilter
                    label="Renewal Within"
                    value={selectedRenewal && selectedRenewal}
                    options={dateOptions}
                    optionLabel="name"
                    placeholder="Select Renewal Date"
                    onChange={onRenewalChange}
                    showClear={true}
                />
                <CalendarFilter
                    label="Inactive Since"
                    selectedDate={selectedInactive}
                    setDate={setSelectedInactive}
                    filterName="Select Inactive Since Date"
                    formatOfDate={dateFormat.US}
                    locale="en-US"
                />
                <MultiSelectFilter
                    label="Subscription Tier"
                    items={subscriptionOptions}
                    selectedItems={selectedSubscriptions && selectedSubscriptions}
                    setSelectedItems={setSelectedSubscriptions}
                    filter={true}
                    filterName="Select Subscription Tier"
                />
            </div>
        </div>
    );
};

export default AnalyticCustomerFilters;
