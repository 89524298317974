import {getAuthConfig} from '../auth/auth-service';
import axios from 'axios';
import {IActionData} from './types';
import {responseType} from '../globalConstants';

export const sendRequest = async (url: string, method: string, responseType: responseType, data: IActionData) => {
    const headers = [];
    const authConfig = await getAuthConfig(true, data.logout, data.getAccessTokenSilently);
    headers.push(authConfig.headers);

    return await axios({
        method: method,
        headers: headers.reduce((r, c) => Object.assign(r, c), {}),
        url: `${authConfig.apiUrl}${url}`,
        data: data.params,
        responseType: responseType,
    });
};
