import React, {FC} from 'react';
import {Chart} from 'react-chartjs-2';
import {Chart as ChartJS, ChartData, registerables} from 'chart.js';
import {IChartProps} from '../../types';
import styles from './ChartCard.module.scss';

ChartJS.register(...registerables);

const ChartCard: FC<IChartProps> = ({data, options, width, height, type, title}) => {
    return (
        <div className={styles.chartCard} style={{width: width, height: height}}>
            <h3 className={styles.title}>{title}</h3>
            <Chart type={type} data={data} options={options} />
        </div>
    );
};

export default ChartCard;
