import React, {FC} from 'react';
import {IInfoCardProps} from '../../types';
import styles from './InfoCard.module.scss';

const InfoCard: FC<IInfoCardProps> = ({header, title1, title2, value1, value2, suffix, prefix}) => {
    return (
        <div className={styles.infoCard}>
            <h3 className={styles.header}>{header}</h3>
            <div className={styles.subInfo}>
                <div className={styles.subInfoText}>{title1}</div>
                <div className={styles.subInfoText}>{`${suffix}${value1}${prefix}`}</div>
            </div>
            <div className={styles.subInfo}>
                <div className={styles.subInfoText}>{title2}</div>
                <div className={styles.subInfoText}>{`${suffix}${value2}${prefix}`}</div>
            </div>
        </div>
    );
};

export default InfoCard;
