import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAuth0} from '@auth0/auth0-react';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {isEmpty} from 'lodash';
import classNames from 'classnames';
import {fetchAnalyticsKpi} from '../../../../store/slices/analyticsKPI/thunks';
import InfoCard from '../InfoCard/InfoCard';
import ChartCard from '../ChartCard/ChartCard';
import {getAnalyticData} from '../../utils';
import {wait} from '../../../Common/utils';
import {useAppDispatch} from '../../../../hooks/redux';
import {selectAnalyticsKpi} from '../../../../store/slices/analyticsKPI/selectors';
import {sendRequest, urls} from '../../../../apiRequestService';
import {setPopUp} from '../../../../store/slices/popUp/popUpSlice';
import {selectLoading} from '../../../../store/slices/selectors';
import {slicesNames} from '../../../../store/slices/constants';
import {analyticsKpiThunkNames} from '../../../../store/slices/analyticsKPI/constants';
import {popUpInfo, requestMethod, responseType} from '../../../../globalConstants';
import {
    headers,
    optionsMRR,
    optionsARR,
    optionsChurn,
    optionsStacked,
    optionsPie,
    titles,
    initialChartData,
    responseStatus,
} from '../../constants';
import {IYtdTotal, IPreviousTotal} from '../../types';
import styles from './AnalyticKPIPage.module.scss';

const AnalyticKPIPage = () => {
    const [revenueGrowth, setRevenueGrowth] = useState<IPreviousTotal>({});
    const [revenue, setRevenue] = useState<IYtdTotal>({});
    const [churnRevenue, setChurnRevenue] = useState<IYtdTotal>({});
    const [siteMRR, setSiteMRR] = useState(initialChartData);
    const [siteARR, setSiteARR] = useState(initialChartData);
    const [currentCustomers, setCurrentCustomers] = useState(initialChartData);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [newTrialSubscriptions, setNewTrialSubscriptions] = useState<IPreviousTotal>({});
    const [newCustomerSubscriptions, setNewCustomerSubscriptions] = useState<IPreviousTotal>({});
    const [customerChurn, setCustomerChurn] = useState<IPreviousTotal>({});
    const [trialAndPaidSubCount, setTrialAndPaidSubCount] = useState(initialChartData);
    const [canceledSubCount, setCanceledSubCount] = useState(initialChartData);
    const [lastUpdateDate, setLastUpdateDate] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    const {logout, getAccessTokenSilently} = useAuth0();
    const selectedKpi = useSelector(selectAnalyticsKpi);
    const dispatch = useAppDispatch();

    const isLoading = useSelector(selectLoading(slicesNames.analyticsKpi, analyticsKpiThunkNames.fetchAnalyticsKpi));

    const actionData = useMemo(
        () => ({
            params: {},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        }),
        [getAccessTokenSilently, logout],
    );

    const fetchAnalyticAfterUpdate = useCallback(
        async (uuid: string) => {
            setIsUpdating(true);
            let attempt = 0;
            let finished = false;
            while (attempt < 20) {
                const requestData = {
                    method: requestMethod.GET,
                    responseType: responseType.JSON,
                    params: {},
                    logout: logout,
                    getAccessTokenSilently: getAccessTokenSilently,
                };
                const response = await sendRequest(
                    `${urls.ANALYTIC_FINANCE}/${uuid}`,
                    requestMethod.GET,
                    responseType.JSON,
                    requestData,
                );
                if (response.data.status === responseStatus.FINISHED) {
                    finished = true;
                    setIsUpdating(false);
                }

                if (finished) {
                    dispatch(fetchAnalyticsKpi(actionData));
                    break;
                } else {
                    await wait(10000);
                    attempt += 1;
                    if (attempt === 20) {
                        setIsUpdating(false);
                        dispatch(
                            setPopUp({
                                popUp: {
                                    data: {
                                        severity: popUpInfo.severities.ERROR,
                                        summary: popUpInfo.summary.ERROR,
                                        detail: 'Updating was failed',
                                        life: 3000,
                                        sticky: '',
                                    },
                                },
                            }),
                        );
                    }
                }
            }
        },
        [actionData, dispatch, getAccessTokenSilently, logout],
    );

    const updateAnalytic = useCallback(async () => {
        const actionData = {
            params: {},
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };
        const response = await sendRequest(urls.ANALYTIC_FINANCE, requestMethod.POST, responseType.JSON, actionData);
        if (response) {
            await fetchAnalyticAfterUpdate(response.data.uuid);
        }
    }, [fetchAnalyticAfterUpdate, getAccessTokenSilently, logout]);

    useEffect(() => {
        dispatch(fetchAnalyticsKpi(actionData));
    }, [actionData, dispatch, getAccessTokenSilently, logout]);

    useEffect(() => {
        if (!isEmpty(selectedKpi)) {
            const {
                revenueGrowth,
                revenue,
                churnRevenue,
                siteMRR,
                siteARR,
                currentCustomers,
                totalCustomers,
                newTrialSubscriptions,
                newCustomerSubscriptions,
                subscriptionChurn,
                trialAndPaidSubCount,
                canceledSubCount,
                lastUpdate,
            } = getAnalyticData(selectedKpi);
            setRevenueGrowth(revenueGrowth);
            setRevenue(revenue);
            setChurnRevenue(churnRevenue);
            setSiteMRR(siteMRR);
            setSiteARR(siteARR);
            setCurrentCustomers(currentCustomers);
            setTotalCustomers(totalCustomers);
            setNewTrialSubscriptions(newTrialSubscriptions);
            setNewCustomerSubscriptions(newCustomerSubscriptions);
            setCustomerChurn(subscriptionChurn);
            setTrialAndPaidSubCount(trialAndPaidSubCount);
            setCanceledSubCount(canceledSubCount);
            setLastUpdateDate(lastUpdate);
        }
    }, [selectedKpi]);

    return (
        <div className={styles.analyticKpiPage}>
            <div className={styles.cardsWrapper}>
                <div className={styles.analyticHeader}>
                    <div className={styles.lastUpdated}>Analytics was updated: {lastUpdateDate}</div>
                    <Button onClick={updateAnalytic} className={classNames(isUpdating && styles.hideElement)}>
                        Update Analytics
                    </Button>
                    <div className={classNames(styles.spinnerWrapper, !isUpdating && styles.hideElement)}>
                        <ProgressSpinner style={{width: '34px', height: '34px'}} />
                    </div>
                </div>
                {isLoading && (
                    <div className="p-datatable-loading-overlay p-component-overlay">
                        <i
                            className={classNames(
                                'p-datatable-loading-icon pi-spin pi pi-spinner',
                                styles.spinnerStyle,
                            )}
                        />
                    </div>
                )}
                <div className={styles.row}>
                    <InfoCard
                        header={headers.REVENUE_GROWTH}
                        title1={titles.VS_PREVIOUS_MONTH}
                        title2={titles.TOTAL_12_MONTHS}
                        value1={revenueGrowth?.previousMonth}
                        value2={revenueGrowth?.total12Month}
                        suffix=""
                        prefix="%"
                    />
                    <InfoCard
                        header={headers.REVENUE}
                        title1={titles.YDT}
                        title2={titles.TOTAL_12_MONTHS}
                        value1={revenue.ytd}
                        value2={revenue.total12Month}
                        suffix="$"
                        prefix=""
                    />
                    <InfoCard
                        header={headers.CHURN_REVENUE}
                        title1={titles.YDT}
                        title2={titles.TOTAL_12_MONTHS}
                        value1={churnRevenue.ytd}
                        value2={churnRevenue.total12Month}
                        suffix="$"
                        prefix=""
                    />
                </div>
                <div className={styles.row}>
                    {!isEmpty(siteMRR) && (
                        <ChartCard
                            data={siteMRR}
                            options={optionsMRR}
                            width="49%"
                            height="15%"
                            type="bar"
                            title="Site MRR"
                        />
                    )}
                    {!isEmpty(siteARR) && (
                        <ChartCard data={siteARR} options={optionsARR} width="24%" type="bar" title="Site ARR" />
                    )}
                    {!isEmpty(currentCustomers) && (
                        <ChartCard
                            data={currentCustomers}
                            options={optionsPie}
                            width="24%"
                            type="pie"
                            title={`Current customers: ${totalCustomers}`}
                        />
                    )}
                </div>
                <div className={styles.row}>
                    <InfoCard
                        header={headers.NEW_TRIAL_SUBSCRIPTIONS}
                        title1={titles.VS_PREVIOUS_MONTH}
                        title2={titles.TOTAL_12_MONTHS}
                        value1={newTrialSubscriptions.previousMonth}
                        value2={newTrialSubscriptions.total12Month}
                        suffix=""
                        prefix="%"
                    />
                    <InfoCard
                        header={headers.NEW_CUSTOMER_SUBSCRIPTIONS}
                        title1={titles.VS_PREVIOUS_MONTH}
                        title2={titles.TOTAL_12_MONTHS}
                        value1={newCustomerSubscriptions.previousMonth}
                        value2={newCustomerSubscriptions.total12Month}
                        suffix=""
                        prefix="%"
                    />
                    <InfoCard
                        header={headers.CUSTOMER_CHURN}
                        title1={titles.VS_PREVIOUS_MONTH}
                        title2={titles.TOTAL_12_MONTHS}
                        value1={customerChurn.previousMonth}
                        value2={customerChurn.total12Month}
                        suffix=""
                        prefix="%"
                    />
                </div>
                <div className={styles.row}>
                    {!isEmpty(trialAndPaidSubCount) && (
                        <ChartCard
                            data={trialAndPaidSubCount}
                            options={optionsStacked}
                            width="49%"
                            type="bar"
                            title="New Subscriptions"
                        />
                    )}
                    {!isEmpty(canceledSubCount) && (
                        <ChartCard
                            data={canceledSubCount}
                            options={optionsChurn}
                            width="49%"
                            type="bar"
                            title="Customer Churn"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AnalyticKPIPage;
