import React, {FC, useState} from 'react';
import {useSelector} from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';
import classNames from 'classnames';
import MenuDivider from '../../Common/Menu/MenuDivider';
import {LINKS, PLANT_PARTNER_ADMIN, userRoles} from '../../../globalConstants';
import {selectUserRole} from '../../../store/slices/profileInfo/selectors';
import styles from './ProductMenu.module.scss';

interface IProductMenuProps {
    open?: boolean;
}

const ProductMenu: FC<IProductMenuProps> = ({open}) => {
    const [showLinks, setShowLinks] = useState(open);

    const userRole = useSelector(selectUserRole);

    const {productName} = useParams();

    const productLink = `/products/${productName}`;

    const clickHandler = () => {
        setShowLinks((oldState) => !oldState);
    };

    return (
        <div className={styles.productMenu}>
            <ul className={styles.menuItems} role="menu">
                <div>
                    {productName !== PLANT_PARTNER_ADMIN && (
                        <>
                            <div className={styles.dropUp}>
                                <button className={styles.dropBtn} onClick={clickHandler}>
                                    Analytics
                                </button>
                                <div
                                    className={classNames(styles.dropUpContent, showLinks ? styles.show : styles.hide)}>
                                    <MenuDivider />
                                    <NavLink
                                        to={`${productLink}${LINKS.ANALYTIC_KPI}`}
                                        className={({isActive}) => (isActive ? styles.active : undefined)}>
                                        {'KPI'}
                                    </NavLink>
                                    <MenuDivider />
                                    <NavLink
                                        to={`${productLink}${LINKS.ANALYTIC_CUSTOMERS}`}
                                        className={({isActive}) => (isActive ? styles.active : undefined)}>
                                        {'Customers'}
                                    </NavLink>
                                </div>
                            </div>
                            <MenuDivider />
                            {userRole !== userRoles.PPT_ANALYTIC && (
                                <>
                                    <NavLink
                                        to={`${productLink}${LINKS.SUPER_USERS}`}
                                        className={({isActive}) => (isActive ? styles.active : undefined)}>
                                        {'Super User'}
                                    </NavLink>
                                    <MenuDivider />
                                    <a href={process.env.REACT_APP_TRIALS_GRAFANA} target="_blank" rel="noreferrer">
                                        System
                                    </a>
                                    <MenuDivider />
                                </>
                            )}
                        </>
                    )}
                    {productName === PLANT_PARTNER_ADMIN && userRole !== userRoles.PPT_ANALYTIC && (
                        <>
                            <a href={process.env.REACT_APP_ADMIN_GRAFANA} target="_blank" rel="noreferrer">
                                System
                            </a>
                            <MenuDivider />
                        </>
                    )}
                </div>
            </ul>
        </div>
    );
};

export default ProductMenu;
