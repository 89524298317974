import React, {FC, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAuth0} from '@auth0/auth0-react';
import CustomersList from '../CustomersList/CustomersList';
import {selectCustomers, selectTotalRecords} from '../../../store/slices/customers/selectors';
import {useAppDispatch} from '../../../hooks/redux';
import {fetchCustomers} from '../../../store/slices/customers/thunks';
import {initialCustomersParams} from '../constants';
import {getMappedCustomers} from '../utils';
import {ICustomersData} from '../../../api/customers/types';
import styles from './Customers.module.scss';

const CustomersPage: FC = () => {
    const [customersParams, setCustomersParams] = useState(initialCustomersParams);
    const [mappedCustomers, setMappedCustomers] = useState([]);

    const {logout, getAccessTokenSilently} = useAuth0();

    const customers = useSelector(selectCustomers);
    const totalRecords = useSelector(selectTotalRecords);

    const dispatch = useAppDispatch();

    const fetchCustomersCompanies = useCallback(() => {
        const actionData = {
            params: customersParams,
            logout: logout,
            getAccessTokenSilently: getAccessTokenSilently,
        };

        dispatch(fetchCustomers(actionData as ICustomersData));
    }, [dispatch, getAccessTokenSilently, logout, customersParams]);

    useEffect(() => {
        setMappedCustomers(getMappedCustomers(customers));
    }, [customers]);

    useEffect(() => {
        fetchCustomersCompanies();
    }, [customersParams, fetchCustomersCompanies]);

    return (
        <div className={styles.customersPage}>
            <CustomersList
                customers={mappedCustomers}
                customersParams={customersParams}
                setCustomersParams={setCustomersParams}
                totalRecords={totalRecords}
            />
        </div>
    );
};

export default CustomersPage;
