import React, {FC, useRef} from 'react';
import moment from 'moment';
import {Button} from 'primereact/button';
import {OverlayPanel} from 'primereact/overlaypanel';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import {getDefaultDateFormat} from '../utils';
import styles from './CalendarFilter.module.scss';

registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);

interface IProps {
    label: string;
    selectedDate: Date[];
    setDate: (arg: Date[]) => void;
    filterName: string;
    formatOfDate: string;
    locale: string;
}

const CalendarFilter: FC<IProps> = ({label, selectedDate, setDate, filterName, formatOfDate, locale}) => {
    const opCalendarRef = useRef(null);

    const dateFormatting = formatOfDate ? formatOfDate : getDefaultDateFormat();
    return (
        <div className={styles.calendarFilter}>
            <div className={styles.labelText}>{label}</div>
            <Button
                className={`p-button-text p-button-plain ${styles.calendarButton}`}
                icon="pi pi-calendar"
                iconPos="right"
                label={
                    selectedDate
                        ? `${moment(selectedDate[0]).format(dateFormatting)} 
          ${selectedDate[1] ? ` - ${moment(selectedDate[1]).format(dateFormatting)}` : ''}`
                        : filterName
                }
                onClick={(e) => opCalendarRef.current.toggle(e)}
                aria-haspopup
                aria-controls="op_calendar"
            />
            <OverlayPanel ref={opCalendarRef} showCloseIcon id="op_calendar">
                <DatePicker
                    onChange={(dates) => setDate(dates)}
                    startDate={selectedDate ? selectedDate[0] : null}
                    endDate={selectedDate ? selectedDate[1] : null}
                    selectsRange
                    inline
                    //style={{border: 'none'}}
                    showWeekNumbers
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat={dateFormatting}
                    locale={locale}
                    weekLabel={'Wk'}
                />
                <Button
                    type="button"
                    className={`p-button-raised p-button-text ${styles.clearButton}`}
                    onClick={() => {
                        setDate(null);
                    }}>
                    Clear
                </Button>
            </OverlayPanel>
        </div>
    );
};

export default CalendarFilter;
