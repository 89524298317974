import React, {FC} from 'react';
import {MultiSelect} from 'primereact/multiselect';
import styles from './MultiSelectFilter.module.scss';

interface IItem {
    id?: number;
    name: string;
}

interface IProps {
    label: string;
    items: IItem[];
    selectedItems: IItem[];
    setSelectedItems: (arg: IItem[]) => void;
    filter: boolean;
    filterName: string;
}

const MultiSelectFilter: FC<IProps> = ({label, items, selectedItems, setSelectedItems, filter, filterName}) => {
    return (
        <div className={styles.multiSelectFilter}>
            <div className={styles.labelText}>{label}</div>
            <MultiSelect
                className={`p-multiselect ${styles.multiselectInput}`}
                value={selectedItems}
                options={items ? items : []}
                onChange={(e) => setSelectedItems(e.value)}
                optionLabel="name"
                filter={filter}
                placeholder={filterName}
            />
        </div>
    );
};

export default MultiSelectFilter;
