import React from 'react';
import LeftBar from '../../../LeftBar/LeftBar';
import CustomerMenu from '../../CustomerMenu/CustomerMenu';
import BillingDetailInfo from '../BillingDetailInfo/BillingDetailInfo';

const BillingDetailsPage = () => {
    return (
        <>
            <LeftBar>
                <CustomerMenu />
            </LeftBar>
            <BillingDetailInfo />
        </>
    );
};

export default BillingDetailsPage;
