import listOfCountries from 'iso3166-2-db/i18n/dispute/UN/en.json';
import {dateFormat, userPermissions, userRoles} from '../../globalConstants';
import {IISOCountries, IISORegion} from '../../globalTypes';

const getExportURL = (data: any, type: any) => {
    return window.URL.createObjectURL(new Blob(data, type));
};

const getDefaultDateFormat = () => {
    return dateFormat.US;
};

const wait = (ms: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

export const getCountryNameByID = (countryId: string) => {
    return (listOfCountries as IISOCountries)[countryId]?.name;
};

export const getStateNameByID = (countryId: string, stateId: string) => {
    return (listOfCountries as IISOCountries)[countryId]?.regions.filter(
        (region: IISORegion) => region.iso === stateId,
    )[0]?.name;
};

export const getRoleByPermissions = (permissions: string[]) => {
    if (permissions.includes(userPermissions.SUPER_ADMIN_WRITE)) {
        return userRoles.SUPER_ADMIN;
    } else if (permissions.includes(userPermissions.PPT_ADMIN_WRITE)) {
        return userRoles.PPT_ADMIN;
    } else {
        return userRoles.PPT_ANALYTIC;
    }
};

export {getExportURL, getDefaultDateFormat, wait};
