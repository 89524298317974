import {fetchProducts, fetchRoles, fetchUser, fetchUsers} from './thunks';
import createGenericSlice from '../createGenericSlice';
import {slicesNames} from '../constants';
import {IUser} from '../../../api/manageTeam/types';
import {defaultPage, defaultRoles, defaultSelectedUser} from './constants';

const reducers = {
    clearSelectedUser: (state: {data: {selectedUser: IUser}}): void => {
        state.data.selectedUser = defaultSelectedUser;
    },
};

export const manageTeamSlice = createGenericSlice({
    name: slicesNames.manageTeam,
    initialState: {
        data: {
            users: [],
            selectedUser: defaultSelectedUser,
            roles: defaultRoles,
            products: [],
            page: defaultPage,
        },
        statuses: {},
        errors: {},
        lastRequestId: {},
    },
    reducers,
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data = {
                    ...state.data,
                    users: payload.result,
                    page: payload.page,
                };
            }
        });

        builder.addCase(fetchUser.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data = {
                    ...state.data,
                    selectedUser: payload,
                };
            }
        });

        builder.addCase(fetchRoles.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data = {
                    ...state.data,
                    roles: [...payload.roles],
                };
            }
        });

        builder.addCase(fetchProducts.fulfilled, (state, {payload}) => {
            if (payload) {
                state.data = {
                    ...state.data,
                    products: payload,
                };
            }
        });
    },
});

export const {resetSlice, clearSelectedUser} = manageTeamSlice.actions;
