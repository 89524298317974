export const initialSuperUserParams = {
    query: {},
    navigation: {
        sort: [
            {
                key: 'createdSuperUser',
                order: 'DESC',
            },
            {
                key: 'companyName',
                order: 'ASC',
            },
        ],
        page: {
            from: 0,
            size: 20,
        },
    },
};
