import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {Dialog} from 'primereact/dialog';
import {urls} from '../../../apiRequestService';
import styles from './ProductDialog.module.scss';

interface IDialog {
    visible: boolean;
    header: string;
    onHideHandler: () => void;
    products: string[];
}

const ProductsDialog: FC<IDialog> = ({visible, onHideHandler, products, header}) => {
    return (
        <Dialog visible={visible} className={styles.dialog} header={`Company Name: ${header}`} onHide={onHideHandler}>
            <div className={styles.wrapper}>
                <div className={styles.productsCaption}>Products :</div>
                <ul>
                    {products.map((product: string, index) => {
                        return (
                            <Link key={index} to={`/${urls.PRODUCTS}/${product}`}>
                                {product}
                            </Link>
                        );
                    })}
                </ul>
            </div>
        </Dialog>
    );
};

export default ProductsDialog;
